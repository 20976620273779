import { Tabs } from 'antd';
import { TabPaneProps, TabsProps } from 'antd/lib/tabs';

const { TabPane } = Tabs;

export { Tabs, TabPane };

export type {
  TabsProps,
  TabPaneProps,
};
