import { AUTH_TOKEN_NAME } from 'Shared/constants/index';
import { IAppStorage } from 'Shared/services/storage';

export interface IAuthTokenStorage {
  token: string | null;
  storeToken: (token: string, remember: boolean) => void;
  deleteToken: () => void;
}

export class AuthTokenStorage implements IAuthTokenStorage {
  constructor(
    private readonly localStorage: IAppStorage,
    private readonly sessionStorage: IAppStorage,
  ) {}

  get token(): string | null {
    const tokenName = AUTH_TOKEN_NAME as string;
    return this.sessionStorage.getItem(tokenName) || this.localStorage.getItem(tokenName);
  }

  storeToken(token: string, remember = true): void {
    const storage = remember ? this.localStorage : this.sessionStorage;
    storage.storeItem(AUTH_TOKEN_NAME as string, token);
  }

  deleteToken(): void {
    const tokenName = AUTH_TOKEN_NAME as string;
    this.localStorage.removeItem(tokenName);
    this.sessionStorage.removeItem(tokenName);
  }
}
