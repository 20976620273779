import { gql } from '@apollo/client';

const JobApplicationStatusFragment = gql`
  fragment JobApplicationStatusData on JobApplicationStatus {
    id
    name
    isPredefined
    sortOrder
  }
`;

export default JobApplicationStatusFragment;