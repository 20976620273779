import { gql } from '@apollo/client';

const ConsultantLevelBaseFragment = gql`
  fragment ConsultantLevelBase on ConsultantLevel {
    id
    name
  }
`;

export default ConsultantLevelBaseFragment;
