import React, { FC, ReactElement } from 'react';
import { LinkButton } from 'Shared/ui';
import NotificationTemplate from './NotificationTemplate';

interface Props {
  message: ReactElement | string;
  actionTitle: string;
  onActionClick: () => void;
}

const NotificationWithAction: FC<Props> = ({ message, actionTitle, onActionClick }) => (
  <NotificationTemplate
    message={message}
    actions={(
      <LinkButton
        size="small"
        onClick={onActionClick}
      >
        {actionTitle}
      </LinkButton>
    )}
  />
);

export default NotificationWithAction;
