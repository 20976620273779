import { gql } from '@apollo/client';

const WorkspaceBaseFragment = gql`
  fragment WorkspaceBaseData on WorkspaceBase {
    id
    name
    logoUrl
  }
`;

export default WorkspaceBaseFragment;
