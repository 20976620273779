import React, { ReactElement, ReactNode } from 'react';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { ArgsProps, MessageType } from 'antd/lib/message';
import { GraphQLError } from 'graphql';
import NotificationWithAction from 'Shared/components/Common/Notification/NotificationWithAction';
import { ErrorResponse } from '@apollo/client/link/error';
import getTime from 'Shared/utils/date/getTime';

export const NOTIFICATION_ELEMENT_CLASS_NAME = 'riverflex-notification';
const NOTIFICATION_DURATION_SHORT = 3; // seconds
const NOTIFICATION_DURATION_LONG = 5; // seconds

type NotificationDuration = 'short' | 'long';
function getNotificationDurationValue(duration?: NotificationDuration): number {
  switch (duration) {
    case 'long':
      return NOTIFICATION_DURATION_LONG;
    case 'short':
      return NOTIFICATION_DURATION_SHORT;
    default:
      return 0;
  }
}

export interface ShowNotificationOptions {
  id: string;
  duration?: NotificationDuration;
  type: NotificationType;
  content: ReactElement | string | number;
  icon?: ReactNode;
}

export type NotificationType = Exclude<ArgsProps['type'], undefined>;

type ShowErrorNotificationOptions = Omit<ShowNotificationOptions, 'id' | 'type' | 'content'>;

const notificationIconMap: Record<NotificationType, ReactNode | undefined> = {
  success: <CheckOutlined />,
  error: <CloseCircleOutlined />,
  info: undefined,
  loading: undefined,
  warning: undefined,
};

const getIconByType = (type: NotificationType): ReactNode | undefined => (
  notificationIconMap[type]
);

export const showNotification = (options: ShowNotificationOptions): MessageType => {
  const {
    id, duration, type, content, icon,
  } = options;

  return message.open({
    key: id,
    duration: getNotificationDurationValue(duration),
    type,
    content,
    className: NOTIFICATION_ELEMENT_CLASS_NAME,
    icon: icon || getIconByType(type),
  });
};

export const hideNotification = (id: string): void => message.destroy(id);
export const hideAllNotifications = (): void => message.destroy();

//ToDo: add error handling type specific
export const showErrorNotification = (
  error: GraphQLError | ErrorResponse['networkError'] | any,
  options?: ShowErrorNotificationOptions,
): void => {
  if (!error) return;
  const errorMessage = error?.message;
  const originalMessage = error?.extensions?.originalError?.message;
  const notificationId = `${error.name || 'error'}-${getTime()}`;
  const defaultErrorMessage = 'There was an unexpected error on your request, Please try again later.';
  showNotification({
    type: 'error',
    id: notificationId,
    content: (
      <NotificationWithAction
        message={ errorMessage || originalMessage || defaultErrorMessage }
        actionTitle="Close"
        onActionClick={() => hideNotification(notificationId)}
      />
    ),
    ...options,
  });
};


