import { gql } from '@apollo/client';

const ProjectRoleDayWorkPlanFragment = gql`
  fragment ProjectRoleDayWorkPlanData on ProjectRoleDayWorkPlan {
    day
    units
  }
`;

export default ProjectRoleDayWorkPlanFragment;
