import { Breadcrumb } from 'antd';
import { BreadcrumbItemProps, BreadcrumbProps } from 'antd/lib/breadcrumb';

const {
  Item,
} = Breadcrumb;

export { Breadcrumb, Item as BreadcrumbItem };

export type {
  BreadcrumbItemProps,
  BreadcrumbProps,
};
