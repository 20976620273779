import {
  ApolloClient, gql, QueryOptions,
} from '@apollo/client';
import { query } from 'Shared/api/helpers';
import { Query, QueryIsEmailAvailableArgs } from '__generated__/graphql/types';
import MeFragment from 'Shared/api/fragments/Me/MeFragment';

export const GET_ME = gql`
  query me {
    me {
      ...MeData
    }
  }
  ${MeFragment}
`;

export const IS_EMAIL_AVAILABLE = gql`
  query isEmailAvailable($email: String!) {
    isEmailAvailable(email: $email)
  }
`;

export const isEmailAvaliable = async (
  client: ApolloClient<any>,
  options: Omit<QueryOptions<QueryIsEmailAvailableArgs>, 'query'>,
): Promise<boolean> => {
  const data = await query<Record<'isEmailAvailable', Query['isEmailAvailable']>, QueryIsEmailAvailableArgs>(client, {
    query: IS_EMAIL_AVAILABLE,
    ...options,
  }).catch();
  return !!data?.isEmailAvailable;
};
