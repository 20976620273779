import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { History } from 'history';

import {
  AuthPayloadDataFragment,
  AvailablePortal,
  LoginWithLinkedInCodeMutation,
  LoginWithLinkedInCodeMutationVariables,
  LoginWithPasswordMutation,
  LoginWithPasswordMutationVariables,
  WorkspaceDataFragment,
} from '__generated__/graphql/types';
import { IAuthTokenStorage } from 'Shared/services/auth/AuthTokenStorage';
import { IActiveWorkspaceStorage } from 'Shared/services/activeWorkspace/ActiveWorkspaceStorage';
import { LOGIN_WITH_LINKEDIN_CODE, LOGIN_WITH_PASSWORD } from 'Shared/api/mutations/auth';
import {
  IAuthService, ISetupAuthOptions, ILogoutOptions,
} from 'Shared/services/auth';
import { ILogger } from 'Shared/services/logger';
import { getAppRootPath, identifyCurrentPortal } from 'Shared/hooks/useIdentifyAppPortal/useIdentifyAppPortal';
import getWorkspaceIdentifierFromLocation from 'Shared/utils/routing/getWorkspaceIdentifierFromLocation';
import { getLoginPagePath, getWorkspacePortalLoginPagePath } from 'Shared/utils/routing/getLoginPagePath';
import { AFTER_LOGIN_REDIRECT } from 'Shared/constants';

export type PasswordLoginOptions = {
  email: string;
  password: string;
  rememberLogin?: boolean;
  portalType: AvailablePortal;
  // When provided, then try to find given workspace in user data and log into it
  workspaceIdentifier?: string;
  allowRedirectAfterLogin?: boolean;
};

export type LinkedInLoginOptions = {
  code: string;
  portalType: AvailablePortal;
  // When provided, then try to find given workspace in user data and log into it
  identifier: string;
};

type IClient = ApolloClient<NormalizedCacheObject | any>;

export class PasswordAuthService implements IAuthService<
PasswordLoginOptions, LoginWithPasswordMutation | void> {
  constructor(
    private client: IClient,
    private readonly activeWorkspaceStorage: IActiveWorkspaceStorage,
    private readonly authTokenStorage: IAuthTokenStorage,
    private readonly history: History,
    private readonly logger: ILogger,
  ) {}

  reInitDeps({ client }: {
    client: IClient;
  }): PasswordAuthService {
    this.client = client;
    return this;
  }

  async login({
    email,
    password,
    rememberLogin,
    portalType,
    workspaceIdentifier,
    allowRedirectAfterLogin = false
  }: PasswordLoginOptions): Promise<void> {
    const response = await this.client.mutate<
    LoginWithPasswordMutation, LoginWithPasswordMutationVariables>({
      mutation: LOGIN_WITH_PASSWORD,
      variables: { email, password },
    });
    if (!response?.data) return;
    const workspace = await this.setupAuthData(response.data.loginWithPassword, {
      rememberLogin: !!rememberLogin,
      workspaceIdentifier,
      portal: portalType,
    });
    const redirectPath = allowRedirectAfterLogin ? localStorage.getItem(AFTER_LOGIN_REDIRECT) || '' : '';

    this.history.push(`/${getAppRootPath(workspace.identifier, portalType)}/${redirectPath}`);
  }

  async loginWithLinked({
    identifier, code, portalType  }: LinkedInLoginOptions): Promise<void> {
    const response = await this.client.mutate<
    LoginWithLinkedInCodeMutation, LoginWithLinkedInCodeMutationVariables>({
      mutation: LOGIN_WITH_LINKEDIN_CODE,
      variables: { identifier, code },
    });
    if (!response?.data) return;

    const workspace = await this.setupAuthData(response.data.loginWithLinkedInCode, {
      rememberLogin: false,
      workspaceIdentifier: identifier,
      portal: portalType,
    });

    this.history.push(`/${getAppRootPath(workspace.identifier, portalType)}`);
  }

  async logout(opions: ILogoutOptions = {
    redirectUrlAfterLogout: `/${getWorkspacePortalLoginPagePath()}`,
  }): Promise<void> {
    this.authTokenStorage.deleteToken();
    await this.client.clearStore();
    this.client.cache.gc();
    this.logger.clearScope();
    this.activeWorkspaceStorage.clear();
    this.history.push(opions.redirectUrlAfterLogout);
  }

  hardLogout(): void {
    const workspaceIdentifier = getWorkspaceIdentifierFromLocation(window.location);
    const portal = identifyCurrentPortal(window.location.pathname, workspaceIdentifier);
    this.authTokenStorage.deleteToken();
    window.location.replace(`/${getLoginPagePath(portal, workspaceIdentifier)}`);
  }

  // eslint-disable-next-line class-methods-use-this
  hardRefresh(pathname = '/'): void {
    window.location.replace(pathname);
  }

  async setupAuthData(
    authPayload: AuthPayloadDataFragment,
    options: ISetupAuthOptions,
  ): Promise<WorkspaceDataFragment> {
    return new Promise((resolve) => {
      const { token, workspaces } = authPayload;
      const portalWorkspaces = workspaces.filter((workspace) => workspace.portals.includes(options.portal));
      this.authTokenStorage.storeToken(token, options.rememberLogin);
      const workspace = workspaces.find((ws) => ws.identifier ===  options.workspaceIdentifier)
        || this.activeWorkspaceStorage.find(portalWorkspaces)
        || workspaces[0];

      if (!this.activeWorkspaceStorage.id) {
        this.activeWorkspaceStorage.storeId(workspace.id);
      }
      setTimeout(() => resolve(workspace), 0);
    });
  }
}
