import { gql } from '@apollo/client';

const UserFragment = gql`
  fragment UserData on User {
    id
    firstName
    lastName
    avatarUrl
    email
  }
`;

export default UserFragment;
