import {
  InvalidateCacheConfig,
} from 'Shared/api/links/InvalidateCacheLink';
import { QueryName, MutationName } from 'Shared/types/api';

const consultantInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  updateConsultant: {
    invalidateOperations: [
      { operationName: 'projectRolesOverview' },
      { operationName: 'consultantsAssignedToProject' },
      { operationName: 'projectDeliverables' },
      // after merging profiles, some consultants may become stale
      { operationName: 'consultants', variables: () => ({}) }
    ]
  },
  createConsultant: {
    invalidateOperations: [
      { operationName: 'consultants', variables: () => ({}) }
    ]
  },
  addConsultantToMarketplace: {
    invalidateOperations: [
      { operationName: 'consultants', variables: () => ({}) }
    ]
  },
  removeConsultantFromMarketplace: {
    invalidateOperations: [
      { operationName: 'consultants', variables: () => ({}) }
    ]
  },
  addConsultantToFavorites: {
    invalidateOperations: [
      { operationName: 'consultants', variables: () => ({}) },
      { operationName: 'talentMarketplace', variables: () => ({}) }
    ]
  },
  removeConsultantFromFavorites: {
    invalidateOperations: [
      { operationName: 'consultants', variables: () => ({}) },
      { operationName: 'talentMarketplace', variables: () => ({}) }
    ]
  },
  requestConsultantContact: {
    invalidateOperations: [
      { operationName: 'talentMarketplace', variables: () => ({}) }
    ]
  },
  createJobApplication: {
    invalidateOperations: [
      { operationName: 'cpJobOpportunity', variables: () => ({}) }
    ]
  },
  updateJobOpportunity: {
    invalidateOperations: [
      { operationName: 'jobOpportunity', variables: () => ({}) }
    ]
  },
  updateJobOpportunityStatus: {
    invalidateOperations: [
      { operationName: 'jobOpportunities', variables: () => ({}) }
    ]
  },
  removeJobOpportunity: {
    invalidateOperations: [
      { operationName: 'jobOpportunities', variables: () => ({}) }
    ]
  }
};

export default consultantInvalidationConfig;
