import { gql } from '@apollo/client';

const ConsultantBaseFragment = gql`
  fragment ConsultantBaseData on ConsultantBase {
    id
    name
    avatarUrl
  }
`;

export default ConsultantBaseFragment;
