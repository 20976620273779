import { gql } from '@apollo/client';
import ConsultantFullFragment from '../Consultant/ConsultantFullFragment';
import JobApplicationStatusFragment from 'Shared/api/fragments/Jobs/JobApplicationStatusFragment';

const JobApplicationFragment = gql`
  fragment JobApplicationData on JobApplication {
    id
    consultant {
      ...ConsultantFullData
    }
    status {
      ...JobApplicationStatusData
    }
    coverLetter
    name
    email
    location
    timezone
    role
    avatarUrl
    cvUrl
    website
    linkedin
    phone
    preferredRoles
    preferredIndustries
    costRate
    description
    availableFrom
  }
  ${ConsultantFullFragment}
  ${JobApplicationStatusFragment}
`;

export default JobApplicationFragment;
