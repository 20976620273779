import React, { FC } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

import { ButtonProps as AntdButtonProps } from 'antd/lib/button/button';

export type ButtonProps = AntdButtonProps;
export type CustomButtonProps = Omit<AntdButtonProps, 'type'>;

export const PrimaryButton: FC<CustomButtonProps> = (props) => (
  <Button type="primary" {...props} />
);

export const SecondaryButton: FC<CustomButtonProps> = (props) => (
  <Button type="default" {...props} />
);

export const TextButton: FC<CustomButtonProps> = (props) => (
  <Button type="text" {...props} />
);

export const LinkButton: FC<CustomButtonProps> = (props) => (
  <Button type="link" {...props} />
);

// LinkButton without offsets, so it's usable within texts
export const TextLinkButton: FC<CustomButtonProps> = styled(LinkButton)({
  padding: 0,
  textAlign: 'inherit',
  whiteSpace: 'initial',
});

// TextButton without offsets, so it's usable within texts
export const InlineTextButton: FC<CustomButtonProps> = styled(TextButton)({
  padding: 0,
  textAlign: 'inherit',
  whiteSpace: 'initial',
});

export const IconButton: FC<CustomButtonProps> = styled(SecondaryButton)({
  border: 'none',
  boxShadow: 'none',
  background: 'transparent',
});
