import { ApolloCache } from '@apollo/client';
import { Nullable } from 'Shared/types/utils';
import {
  AuthPayload,
  ClientContact,
  ClientContract,
  ConsultantContract,
  ConsultantGroupDataFragment,
  ConsultantGroupsQuery,
  ConsultantLevelDataFragment,
  ConsultantLevelsQuery,
  ExpenseCategoriesQuery,
  ExpenseCategoryDataFragment,
  MeDataFragment,
  MeQuery,
  Project,
  ProjectConsultantContractsQuery,
  ProjectDeliverable,
  ProjectRole,
  ProjectScenarioDataFragment,
  ProjectScenariosQuery,
  Scalars,
  WorkspaceLocationDataFragment,
  WorkspaceLocationsQuery,
  WorkspaceDataFragment,
  WorkspaceContractTemplateDataFragment,
  JobApplicationStatusDataFragment,
  WorkspaceContractTemplatesQuery,
  JobApplicationStatusesQuery,
} from '__generated__/graphql/types';
import { namedOperations } from '__generated__/graphql/named-operations-object';
import {
  GET_PROJECT_CONSULTANT_CONTRACTS, GET_PROJECT_SCENARIOS,
} from 'WorkspacePortal/api/queries/project';
import { GET_ME } from 'Shared/api/queries/user';
import { activeWorkspaceStorage } from 'globalModules';
import AuthPayloadFragment from 'Shared/api/fragments/AuthPayload/AuthPayloadFragment';
import ClientContactFragment from 'WorkspacePortal/api/fragments/ClientContact/ClientContactFragment';
import ClientContractFragment from 'WorkspacePortal/api/fragments/ClientContract/ClientContractFragment';
import ConsultantContractFragment from 'WorkspacePortal/api/fragments/ConsultantContract/ConsultantContractFragment';
import ProjectFragment from 'WorkspacePortal/api/fragments/Project/ProjectFragment';
import ProjectDeliverableFragment from 'WorkspacePortal/api/fragments/ProjectDeliverable/ProjectDeliverableFragment';
import ProjectRoleFragment from 'WorkspacePortal/api/fragments/ProjectRole/ProjectRoleFragment';
import { GET_CONSULTANT_LEVELS, GET_EXPENSE_CATEGORIES, GET_WORKSPACE_LOCATIONS } from 'WorkspacePortal/api/queries/settings';
import { GET_CONSULTANT_GROUPS } from 'WorkspacePortal/api/queries/consultant';
import { identifyFragment } from 'Shared/api/cache/cache';
import { getFragmentTypeName } from 'Shared/api/helpers';
import ConsultantLevelFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelFragment';
import { GET_WORKSPACE_CONTRACT_TEMPLATES } from 'WorkspacePortal/api/queries/contract';
import { GET_JOB_APPLICATION_STATUSES } from 'WorkspacePortal/api/queries/jobs';

type EntityId = Scalars['ID'];

export interface ICachedDataRepo {
  getProject: (id: EntityId, scenarioSlug: string) => Nullable<Project>;
  getClientContract: (id: EntityId) => Nullable<ClientContract>;
  getClientContact: (id: EntityId) => Nullable<ClientContact>;
  getConsultantContract: (id: EntityId) => Nullable<ConsultantContract>;
  getDeliverable: (id: EntityId) => Nullable<ProjectDeliverable>;
  getProjectRole: (id: EntityId) => Nullable<ProjectRole>;
  getProjectConsultantContracts: (id: EntityId) => Nullable<ProjectConsultantContractsQuery['projectConsultantContracts']>;
  getCurrentUser: () => Nullable<MeDataFragment>;
  getWorkspaces: () => WorkspaceDataFragment[] | undefined;
  getActiveWorkspace: () => WorkspaceDataFragment | undefined;
  getConsultantLevels: () => ConsultantLevelDataFragment[] | undefined;
  getWorkspaceLocations: () => WorkspaceLocationDataFragment[] | undefined;
  getConsultantGroups: () => ConsultantGroupDataFragment[] | undefined;
  getExpenseCategories: () => ExpenseCategoryDataFragment[] | undefined;
  getActiveScenario: (projectId: EntityId) => ProjectScenarioDataFragment | undefined;
  getConsultantLevel: (levelId: EntityId) => Nullable<ConsultantLevelDataFragment>;
  getWorkspaceContractTemplates: () => WorkspaceContractTemplateDataFragment[] | undefined;
  getJobApplicationStatuses: () => JobApplicationStatusDataFragment[] | undefined;
}

class CachedDataRepository implements ICachedDataRepo {
  constructor(readonly cache: ApolloCache<any>) {}

  getJobApplicationStatuses: ICachedDataRepo['getJobApplicationStatuses'] = () => {
    const data = this.cache.readQuery<JobApplicationStatusesQuery>({
      query: GET_JOB_APPLICATION_STATUSES,
    });
    return data?.jobApplicationStatuses;
  };

  getProject: ICachedDataRepo['getProject'] = (id, scenarioSlug) => (
    this.cache.readFragment<Project>({
      id: this.cache.identify({
        id,
        scenario: { slug: scenarioSlug },
        __typename: getFragmentTypeName(ProjectFragment),
      }),
      fragment: ProjectFragment,
      fragmentName: namedOperations.Fragment.ProjectData,
    })
  );

  getClientContract = (id: EntityId): ReturnType<ICachedDataRepo['getClientContract']> => (
    this.cache.readFragment<ClientContract>({
      id: identifyFragment(id, ClientContractFragment),
      fragment: ClientContractFragment,
      fragmentName: namedOperations.Fragment.ClientContractData,
    })
  );

  getConsultantContract = (id: EntityId): ReturnType<ICachedDataRepo['getConsultantContract']> => (
    this.cache.readFragment<ConsultantContract>({
      id: identifyFragment(id, ConsultantContractFragment),
      fragment: ConsultantContractFragment,
      fragmentName: namedOperations.Fragment.ConsultantContractData,
    })
  );

  getDeliverable = (id: EntityId): ReturnType<ICachedDataRepo['getDeliverable']> => (
    this.cache.readFragment<ProjectDeliverable>({
      id: identifyFragment(id, ProjectDeliverableFragment),
      fragment: ProjectDeliverableFragment,
      fragmentName: namedOperations.Fragment.ProjectDeliverableData,
    })
  );

  getProjectRole = (id: EntityId): ReturnType<ICachedDataRepo['getProjectRole']> => (
    this.cache.readFragment<ProjectRole>({
      id: identifyFragment(id, ProjectRoleFragment),
      fragment: ProjectRoleFragment,
      fragmentName: namedOperations.Fragment.ProjectRoleData,
    })
  );

  getClientContact = (id: EntityId): ReturnType<ICachedDataRepo['getClientContact']> => (
    this.cache.readFragment<ClientContact>({
      id: identifyFragment(id, ClientContactFragment),
      fragment: ClientContactFragment,
      fragmentName: namedOperations.Fragment.ClientContactData,
    })
  );

  getProjectConsultantContracts = (projectId: EntityId): ReturnType<ICachedDataRepo['getProjectConsultantContracts']> => {
    const data = this.cache.readQuery<ProjectConsultantContractsQuery>({
      query: GET_PROJECT_CONSULTANT_CONTRACTS,
      variables: {
        projectId,
      },
    });
    return data?.projectConsultantContracts || null;
  };

  getCurrentUser = (): ReturnType<ICachedDataRepo['getCurrentUser']> => {
    const queryData = this.cache.readQuery<MeQuery>({ query: GET_ME });
    return queryData?.me || null;
  };

  getWorkspaces = (): ReturnType<ICachedDataRepo['getWorkspaces']> => {
    const userData = this.getCurrentUser();
    if (userData?.workspaces) return userData?.workspaces;

    // users who just registered and don't have user data yet
    const authPayload = this.cache.readFragment<AuthPayload>({
      id: 'AuthPayload:{}',
      fragment: AuthPayloadFragment,
      fragmentName: namedOperations.Fragment.AuthPayloadData,
    });
    return authPayload?.workspaces;
  };

  getActiveWorkspace: ICachedDataRepo['getActiveWorkspace'] = () => {
    const workspaces = this.getWorkspaces();
    return workspaces ? activeWorkspaceStorage.find(workspaces) : undefined;
  };

  getConsultantLevels: ICachedDataRepo['getConsultantLevels'] = () => {
    const data = this.cache.readQuery<ConsultantLevelsQuery>({
      query: GET_CONSULTANT_LEVELS,
    });
    return data?.consultantLevels;
  };

  getConsultantLevel: ICachedDataRepo['getConsultantLevel'] = (levelId) => {
    return this.cache.readFragment<ConsultantLevelDataFragment>({
      id: identifyFragment(levelId, ConsultantLevelFragment),
      fragment: ConsultantLevelFragment,
      fragmentName: namedOperations.Fragment.ConsultantLevelData,
    });
  };

  getWorkspaceLocations: ICachedDataRepo['getWorkspaceLocations'] = () => {
    const data = this.cache.readQuery<WorkspaceLocationsQuery>({
      query: GET_WORKSPACE_LOCATIONS,
    });
    return data?.workspaceLocations;
  };

  getConsultantGroups: ICachedDataRepo['getConsultantGroups'] = () => {
    const data = this.cache.readQuery<ConsultantGroupsQuery>({
      query: GET_CONSULTANT_GROUPS,
    });
    return data?.consultantGroups;
  };

  getWorkspaceContractTemplates: ICachedDataRepo['getWorkspaceContractTemplates'] = () => {
    const data = this.cache.readQuery<WorkspaceContractTemplatesQuery>({
      query: GET_WORKSPACE_CONTRACT_TEMPLATES,
    });
    return data?.workspaceContractTemplates;
  };

  getExpenseCategories: ICachedDataRepo['getExpenseCategories'] = () => {
    const data = this.cache.readQuery<ExpenseCategoriesQuery>({
      query: GET_EXPENSE_CATEGORIES,
    });
    return data?.expenseCategories;
  };

  getActiveScenario: ICachedDataRepo['getActiveScenario'] = (projectId) => {
    const data = this.cache.readQuery<ProjectScenariosQuery>({
      query: GET_PROJECT_SCENARIOS,
      variables: { projectId },
    });
    return data?.projectScenarios.find((scenario) => !!scenario.isActive);
  };
}

export default CachedDataRepository;
