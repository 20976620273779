import { ActiveWorkspaceStorage } from 'Shared/services/activeWorkspace/ActiveWorkspaceStorage';
import { AuthTokenStorage } from 'Shared/services/auth/AuthTokenStorage';
import { AppStorage } from 'Shared/services/storage';
import { initLogger } from 'Shared/services/logger';
import initClient, { setupClientLinks } from 'Shared/api/client';
import initCache from 'Shared/api/cache/cache';
import { createBrowserHistory } from 'history';
import { PasswordAuthService } from 'Shared/services/auth/PasswordAuthService';
import CachedDataRepository from 'Shared/api/cache/CachedDataRepository';
import AdminPortalActiveWorkspaceStorage from 'AdminPortal/services/AdminPortalActiveWorkspaceStorage';
import AdminPortalAuthService from 'AdminPortal/services/AdminPortalAuthService';

const history = createBrowserHistory();

const localStorage = new AppStorage(window.localStorage);
const sessionStorage = new AppStorage(window.sessionStorage);

const activeWorkspaceStorage = new ActiveWorkspaceStorage(localStorage);
const adminPortalWorkspaceStorage = new AdminPortalActiveWorkspaceStorage(localStorage);
const authTokenStorage = new AuthTokenStorage(localStorage, sessionStorage);
const logger = initLogger({
  activeWorkspaceStorage,
  history
});

const cache = initCache({
  activeWorkspaceStorage
});
const apiClient = initClient({
  cache,
});
const cachedDataRepository = new CachedDataRepository(cache);

const passwordAuthService = new PasswordAuthService(
  apiClient,
  activeWorkspaceStorage,
  authTokenStorage,
  history,
  logger,
);
const adminPoralAuthService = new AdminPortalAuthService(
  apiClient,
  adminPortalWorkspaceStorage,
  authTokenStorage,
  history,
);

setupClientLinks({
  client: apiClient,
  logger,
  activeWorkspaceStorage,
  adminPortalWorkspaceStorage,
  passwordAuthService,
  adminPoralAuthService,
  authTokenStorage,
  cache,
  cachedDataRepository,
});

export {
  apiClient,
  cache,
  cachedDataRepository,
  activeWorkspaceStorage,
  authTokenStorage,
  logger,
  passwordAuthService,
  localStorage,
  sessionStorage,
  history,
  adminPortalWorkspaceStorage,
  adminPoralAuthService,
};
