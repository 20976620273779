import React, { FC } from 'react';
import theme from 'Shared/constants/theme';
import { Descriptions, DescriptionsProps } from 'antd';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import styled, { CSSObject } from 'styled-components';
import { Title } from './Typography';

const {
  Item: DescriptionsItem,
} = Descriptions;

const tableBorderStyle = `0.5px solid ${theme.colors.neutral[400]}`;
const CellCommonStyles: CSSObject = {
  borderRight: tableBorderStyle,
  padding: '12px 24px',
};

const VerticalDescriptionsTable = styled.table({
  tableLayout: 'fixed',
  border: tableBorderStyle,
  width: '100%',
  backgroundColor: theme.colors.white.base,
});

const VerticalDescriptionsLabel = styled.th({
  ...CellCommonStyles,
  backgroundColor: theme.colors.neutral[200],
  fontWeight: 400,
  fontSize: theme.fontSize.s,
  textAlign: 'start',
});

const VerticalDescriptionsValue = styled.td(CellCommonStyles);

const VerticalDescriptionsRow = styled.tr({
  borderBottom: `0.5px solid ${theme.colors.neutral[400]}`,
});

/** Usage
 <VerticalDescriptions title="Title">
  <VerticalDescriptionsRow>
    <VerticalDescriptionsLabel>Phone number</VerticalDescriptionsLabel>
    <VerticalDescriptionsValue>+32 23 34343 43</VerticalDescriptionsValue>
  </VerticalDescriptionsRow>

  <VerticalDescriptionsRow>
    <VerticalDescriptionsLabel>Email</VerticalDescriptionsLabel>
    <VerticalDescriptionsValue>mail@mail.mail</VerticalDescriptionsValue>
  </VerticalDescriptionsRow>
 </VerticalDescriptions>
 */
const VerticalDescriptions: FC<{ title?: string, className?: string }> = ({
  title,
  className,
  children,
}) => (
  <div className={className}>
    {!!title && <Title level={5} className='mb-16'>{title}</Title>}
    <VerticalDescriptionsTable>
      <tbody>
        {children}
      </tbody>
    </VerticalDescriptionsTable>
  </div>
);

const VerticalDescriptionsActions: FC = ({ children }) => {
  return (
    <VerticalDescriptionsValue style={{ width: 215 }}>
      {children}
    </VerticalDescriptionsValue>
  );
};


export type { DescriptionsProps, DescriptionsItemProps };
export {
  Descriptions,
  DescriptionsItem,
  VerticalDescriptions,
  VerticalDescriptionsLabel,
  VerticalDescriptionsRow,
  VerticalDescriptionsValue,
  VerticalDescriptionsActions,
};
