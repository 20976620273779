import { List, ListProps } from 'antd';
import {
  ListItemMetaProps, ListItemProps,
} from 'antd/lib/list';

const {
  Item: ListItem,
} = List;

const {
  Meta: ListItemMeta,
} = ListItem;

export {
  List,
  ListItem,
  ListItemMeta,
};

export type {
  ListProps,
  ListItemProps,
  ListItemMetaProps,
};
