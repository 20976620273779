import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';

const FinDashboardProjectFragment = gql`
  fragment FinDashboardProjectData on Project {
    id
    name
    stage
    scenario {
      ...ProjectScenarioRequiredData
    }
    winProbability
    currency
    assignedUser {
      id
      firstName
      lastName
    }
  }
  ${ProjectScenarioRequiredDataFragment}
`;

const FinDashboardProjectClientFragment = gql`
  fragment FinDashboardProjectClientData on Project {
    ...FinDashboardProjectData
    client {
      id
      name
    }
    scenario {
      ...ProjectScenarioRequiredData
      projectType
      startDate
      endDate
    }
    winProbability
  }
  ${FinDashboardProjectFragment}
  ${ProjectScenarioRequiredDataFragment}
`;

const FinDashboardWorkspaceClientFragment = gql`
  fragment FinDashboardWorkspaceClientData on WorkspaceClient {
    id
    name
  }
`;

const FinDashboardPeriodFragment = gql`
  fragment FinDashboardPeriodData on Period {
    id
    position
    type
    periodStartDate
  }
`;

const FinDashboardGroupValueFragment = gql`
  fragment FinDashboardGroupValueData on FinDashboardGroupValue {
    ...FinDashboardWorkspaceClientData
    ...FinDashboardProjectClientData
    ...FinDashboardPeriodData
  }
  ${FinDashboardWorkspaceClientFragment}
  ${FinDashboardProjectClientFragment}
  ${FinDashboardPeriodFragment}
`;

const FinDashboardTotalFragment = gql`
  fragment FinDashboardTotalData on FinDashboardTotal {
    id
    costActive
    costActiveOther
    costActiveInternal
    costActiveExternal
    revenueActive
    grossMarginActive
    costInactive
    costInactiveOther
    costInactiveInternal
    costInactiveExternal
    revenueInactive
    grossMarginInactive
  }
`;

const FinDashboardGroupFragment = gql`
  fragment FinDashboardGroupData on FinDashboardGroup {
    id
    level
    value {
      ...FinDashboardGroupValueData
    }
  }

  ${FinDashboardGroupValueFragment}
`;

const FinDashboardReportBundleFragment = gql`
  fragment FinDashboardReportBundleData on FinDashboardReportBundle {
    rows {
      ...FinDashboardGroupData
    }
    columns {
      ...FinDashboardGroupData
    }
    data {
      ...FinDashboardTotalData
    }
  }
  ${FinDashboardGroupFragment}
  ${FinDashboardTotalFragment}
`;

export default FinDashboardReportBundleFragment;
