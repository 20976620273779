import { Radio } from 'antd';
import { RadioProps, RadioGroupProps, RadioChangeEvent } from 'antd/lib/radio';

const {
  Group,
  Button,
} = Radio;

export {
  Radio,
  Group as RadioGroup,
  Button as RadioButton,
};

export type { RadioProps, RadioGroupProps, RadioChangeEvent };
