import {
  InvalidateCacheConfig,
  VariablesGetterProps,
  VariablesGetterReturnType,
} from 'Shared/api/links/InvalidateCacheLink';
import {
  Scalars,
  CreateProjectExpenseMutationVariables,
  RemoveProjectExpenseMutationVariables,
  UpdateProjectExpenseMutationVariables,
} from '__generated__/graphql/types';
import { QueryName, MutationName } from 'Shared/types/api';

type MutationOptions = VariablesGetterProps<
CreateProjectExpenseMutationVariables
| UpdateProjectExpenseMutationVariables
| RemoveProjectExpenseMutationVariables,
ExpensesInvalidationContext>;

export type ExpensesInvalidationContext = {
  meta: {
    projectId: Scalars['ID'];
    scenarioSlug: string;
  }
};

const projectExpensesInvalivalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  createProjectExpense: {
    extend: 'updateProjectExpense',
    invalidateOperations: [
      {
        operationName: 'projectExpenses',
        variables: ({ variables }: MutationOptions): VariablesGetterReturnType => {
          const {
            projectId,
            scenarioSlug,
          } = variables as CreateProjectExpenseMutationVariables || {};
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
    ],
  },
  removeProjectExpense: {
    extend: 'updateProjectExpense',
  },
  updateProjectExpense: {
    invalidateOperations: [
      {
        operationName: 'expensesOverview',
        variables: ({ variables, context }: MutationOptions):
        VariablesGetterReturnType => {
          const projectId = (variables as CreateProjectExpenseMutationVariables).projectId
            || context.meta?.projectId;
          const scenarioSlug = (variables as CreateProjectExpenseMutationVariables).scenarioSlug
            || context.meta?.scenarioSlug;
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      {
        operationName: 'budgetOverview',
        variables: ({ context }: MutationOptions):
        VariablesGetterReturnType => {
          const { projectId, scenarioSlug } = context?.meta || {};
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      {
        operationName: 'workspaceProject',
        variables: ({ context }: MutationOptions):
        VariablesGetterReturnType => {
          const { projectId, scenarioSlug } = context?.meta || {};
          return {
            projectId,
            scenarioSlug,
          };
        },
      }
    ],
  },
};

export default projectExpensesInvalivalidationConfig;
