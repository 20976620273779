import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
  DataProxy,
  ApolloError,
  Reference,
  ApolloCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { Modifier } from '@apollo/client/cache/core/types/common';

import { QueryName, FragmentName } from 'Shared/types/api';
import { ILogger } from 'Shared/services/logger';
import { isOperationMutation } from 'Shared/api/helpers';

type FragmentOptions = Omit<DataProxy.WriteFragmentOptions<any, any>, 'data' | 'fragmentName'> & {
  fragmentName?: FragmentName;
};

type CollectionVariables<TVars extends Record<any, any>> = Partial<Record<QueryName, TVars>>;

export interface AddEntityToCollectionContext<TVars = CollectionVariables<any>> {
  addEntityToCollection: {
    collectionsToBeUpdated: QueryName[];
    collectionsVariables?: TVars,
    fragmentOptions: FragmentOptions;
    mapResponseToFragment?: (responseData: any) => { __typename: string, [key: string]: any };
  };
}

/**
 * Implemented this link instead of useCreateEntity hook
 * Usage
 * import { AddEntityToCollectionContext } from 'Shared/api/links/AddEntityToCacheLink';
 * const context: AddEntityToCollectionContext = {
    addEntityToCollection: {
      collectionsToBeUpdated: ['collectionName'],
      fragmentOptions: {
        fragment: YourFragment,
        fragmentName: 'YourFragmentDataName',
      },
    },
  };
 * const [create] = useGeneratedGraphqlMutation()
 * Run mutation with created context:
 * create({
 *  variable: { name: '123' },
 *  context: context
 * })
 * Apollo docs
 * https://www.apollographql.com/docs/react/api/link/introduction/#creating-a-custom-link
 */
class AddEntityToCacheLink extends ApolloLink {
  constructor(
    readonly cache: ApolloCache<NormalizedCacheObject>,
    readonly logger: ILogger,
  ) {
    super();
  }

  // eslint-disable-next-line class-methods-use-this
  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const context = operation.getContext() as AddEntityToCollectionContext;

    return forward(operation).map((response) => {
      if (response.errors || !response.data) return response;

      const isMutation = isOperationMutation(operation);
      if (!isMutation || !context.addEntityToCollection?.collectionsToBeUpdated) return response;
      this.updateCollection(context.addEntityToCollection, response.data);
      return response;
    });
  }

  onError(error: ApolloError): void {
    this.logger.log(error);
  }

  private updateCollection(
    config: AddEntityToCollectionContext['addEntityToCollection'],
    response: FetchResult,
  ): void {
    try {
      const fields = config.collectionsToBeUpdated.reduce((acc, name) => ({
        ...acc,
        [this.getCollectionName(name, config)]: this.createCacheModifier({
          response,
          config,
        }),
      }), {});
      this.cache.modify({ fields });
    } catch (err) {
      this.logger.log(err as Error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getCollectionName(
    collectionName: QueryName,
    config: AddEntityToCollectionContext['addEntityToCollection'],
  ): string {
    const collectionVariables = config.collectionsVariables?.[collectionName];
    if (!collectionVariables) return collectionName;
    return `${collectionName}(${JSON.stringify(collectionVariables)})`;
  }

  private createCacheModifier({
    response,
    config,
  }: { response: FetchResult, config: AddEntityToCollectionContext['addEntityToCollection'] }): Modifier<any> {
    return (existingData) => {
      const responseData = Object.entries(response).find(([key]) => key !== '__typename')?.pop();
      if (!responseData) {
        return existingData;
      }

      const newDataFragments: Reference[] = [responseData].flat()
        .reduce((acc, data) => {
          const newDataFragment = this.cache.writeFragment({
            data: config.mapResponseToFragment?.(data) || data,
            ...config.fragmentOptions,
          });
          return [...acc, newDataFragment];
        }, []);

      if (!newDataFragments.filter((item) => !!item).length) return existingData;

      if (Array.isArray(existingData)) {
        return [...existingData, ...newDataFragments];
      }
      if (existingData.nodes) {
        return {
          ...existingData,
          nodes: [...existingData.nodes, ...newDataFragments],
        };
      }
      return existingData;
    };
  }
}

export default AddEntityToCacheLink;
