import { gql } from '@apollo/client';

const ProjectRoleOnboardingStateFragment = gql`
  fragment ProjectRoleOnboardingStateData on ProjectRoleOnboardingState {
    id
    startDate
    inviteEmail
    completedRequirements
  }
`;

export default ProjectRoleOnboardingStateFragment;
