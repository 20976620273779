import { gql } from '@apollo/client';

const ProjectScenarioRequiredDataFragment = gql`
  fragment ProjectScenarioRequiredData on ProjectScenario {
    id
    slug
    projectType
  }
`;

export default ProjectScenarioRequiredDataFragment;
