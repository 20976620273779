import { Tag } from 'antd';
import { TagProps as AntdTagProps, TagType } from 'antd/lib/tag';
import { PresetColorType } from 'antd/lib/_util/colors';
import styled, { CSSObject } from 'styled-components';

type TagProps = AntdTagProps & CustomProps;

interface CustomProps {
  ellipsis?: boolean;
}

const StyledTag = styled(Tag)(({ ellipsis }: CustomProps) => {
  if (ellipsis) {
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    } as CSSObject;
  }

  return {};
});

export {
  StyledTag as Tag,
};

export type { TagProps, TagType, PresetColorType };
