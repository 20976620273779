import { Popover, PopoverProps } from 'antd';
import { createGlobalStyle } from 'styled-components';

export const PopoverWithoutPaddingsStyles = createGlobalStyle(({
  overlayClassName,
}: {
  overlayClassName: string;
}) => ({
  [`.${overlayClassName}`]: {
    '.ant-popover-title, .ant-popover-inner-content, .ant-popover-message-title': {
      padding: 0,
    },
  },
}));

export { Popover };

export type { PopoverProps };
