import { gql } from '@apollo/client';

const CountryFragment = gql`
  fragment CountryData on Country {
    id
    name
    isoKey
    externalKey
  }
`;

export default CountryFragment;
