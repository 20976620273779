import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client';

import {
  showNotification,
  hideNotification,
  ShowNotificationOptions,
} from 'Shared/services/notifications';
import { mergeWithDefaultData } from 'Shared/utils';

type NotificationOptions = Omit<ShowNotificationOptions, 'id' | 'type'> & {
  type?: ShowNotificationOptions['type'];
};

type ApiNotification = {
  beforeCall?: NotificationOptions;
  afterSuccessCall?: NotificationOptions;
};

export interface ApiNotificationContext {
  apiNotification: ApiNotification;
}

export const API_NOTIFICATION_ID = 'ApiNotification';
const DEFAULT_NOTIFICATION_OPTIONS: ShowNotificationOptions = {
  id: API_NOTIFICATION_ID,
  type: 'success',
  duration: 'short',
  content: '',
};

/**
 * @todo may be pass notification service to constructor?
 * Usage
 * import { ApiNotificationContext } from 'Shared/api/links/ApiNotificationLink';
 * const apiNotification: AddEntityToCollectionContext = {
    apiNotification: {
      beforeCall: {
        content: 'Loading some data'
        // type and duration can be optionally re-defined
      },
      afterSuccessCall: {
        content: 'Successfully loaded some data'
        // type and duration can be optionally re-defined: type: "error", duration: 8[seconds]
      }
    }
  };
 * const [create] = useGeneratedGraphqlMutation()
 * const onClick = () => {
 *  create({ variable: { name: 'test' }, context: apiNotification })
 * }
 * Apollo docs about creation custom links:
 * https://www.apollographql.com/docs/react/api/link/introduction/#creating-a-custom-link
 * https://www.apollographql.com/blog/frontend/apollo-link-creating-your-custom-graphql-client-c865be0ce059/
 */
class ApiNotificationLink extends ApolloLink {
  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const context = operation.getContext() as ApiNotificationContext;
    if (context?.apiNotification?.beforeCall) {
      this.showBeforeOperationNotification(context.apiNotification.beforeCall);
    }

    // https://www.apollographql.com/blog/frontend/apollo-link-creating-your-custom-graphql-client-c865be0ce059/
    // need to subscribe to operation for handling error state correctly
    return new Observable((observer) => {
      const subscriber = {
        next: observer.next.bind(observer),
        error: (error: any) => {
          this.onError();
          observer.error(error);
        },
        complete: observer.complete.bind(observer),
      };
      const subscription = forward(operation).map((response) => {
        if (response.errors || !response.data) {
          if (context?.apiNotification?.beforeCall) this.hideNotification();
          return response;
        }

        if (context?.apiNotification?.afterSuccessCall) {
          this.showSuccessNotification(context.apiNotification.afterSuccessCall);
        }
        return response;
      }).subscribe(subscriber);

      return () => {
        subscription.unsubscribe();
      };
    });
  }

  onError(): void {
    this.hideNotification();
  }

  // eslint-disable-next-line class-methods-use-this
  private showSuccessNotification(config: NotificationOptions): void {
    const options: ShowNotificationOptions = mergeWithDefaultData(DEFAULT_NOTIFICATION_OPTIONS, {
      type: 'success',
      ...config,
    });
    showNotification(options);
  }

  // eslint-disable-next-line class-methods-use-this
  private showBeforeOperationNotification(config: NotificationOptions): void {
    const options: ShowNotificationOptions = mergeWithDefaultData(DEFAULT_NOTIFICATION_OPTIONS, {
      type: 'loading',
      ...config,
    });
    showNotification(options);
  }

  // eslint-disable-next-line class-methods-use-this
  private hideNotification(): void {
    hideNotification(API_NOTIFICATION_ID);
  }
}

export default ApiNotificationLink;
