import { Form } from 'antd';
import { FormItemProps, FormProps } from 'antd/lib/form';

const {
  Item, List,
} = Form;

export {
  Form,
  Item as FormItem,
  List as FormList,
};

export type { FormItemProps, FormProps };
