import { gql } from '@apollo/client';

const WorkspaceUserClient = gql`
  fragment WorkspaceUserClientData on WorkspaceClient {
    id
    name
  }
`;


const WorkspaceUserConsultantFragment = gql`
  fragment WorkspaceUserConsultantData on WorkspaceConsultant {
    id
    name
    email
    avatarUrl
  }
`;


const WorkspaceUserFragment = gql`
  fragment WorkspaceUserData on WorkspaceUser {
    id
    accessLevel
    isPending
    position
    firstName
    lastName
    avatarUrl
    email
    lastVisitedAt
    client {
      ...WorkspaceUserClientData
    }
    consultant {
      ...WorkspaceUserConsultantData
    }
  }
  ${WorkspaceUserClient}
  ${WorkspaceUserConsultantFragment}
`;

export default WorkspaceUserFragment;
