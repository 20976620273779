import { InMemoryCache, TypedDocumentNode, DocumentNode } from '@apollo/client';
import { getFragmentTypeName } from 'Shared/api/helpers';
import { Fragment } from 'Shared/types/api';

import generatedIntrospection from '__generated__/graphql/introspection-result';
import createTypePolicies, { Props } from './typePolicies';

let cache: InMemoryCache;

function initCache(props: Props): typeof cache {
  if (cache) return cache;

  cache = new InMemoryCache({
    typePolicies: createTypePolicies(props),
    // Generated via graphql-code-generator fragment-matcher
    // Please see https://www.graphql-code-generator.com/docs/plugins/fragment-matcher#usage-with-apollo-client-3
    possibleTypes: generatedIntrospection.possibleTypes,
  });
  return cache;
}

export function writeToCache<TQuery, TQueryVariables = any>(query: TypedDocumentNode, data: TQuery): void {
  cache.writeQuery<TQuery, TQueryVariables>({
    query,
    id: 'ROOT_QUERY',
    data,
  });
}

export function removeFragmentByFieldValues(
  fragmentTypeName: Fragment,
  byFieldValues: Record<string, number | string>,
): void {
  const data = cache.extract();
  if (!data) return;

  const valuesKeys = Object.keys(byFieldValues);

  Object.keys(data).forEach((keyName) => {
    const fragmentData = data[keyName];
    if (fragmentData?.__typename !== fragmentTypeName) return;

    const shouldFragmentBeDeleted = valuesKeys.every((fieldName) => {
      return fragmentData[fieldName] === byFieldValues[fieldName];
    });
    if (!shouldFragmentBeDeleted) return;

    cache.evict({ id: keyName });
  });
  cache.gc();
}

export function identifyFragment(id: string, fragment: DocumentNode): string | undefined {
  return cache.identify({ id, __typename: getFragmentTypeName(fragment) });
}

export default initCache;
