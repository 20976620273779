import { gql } from '@apollo/client';

const ProjectMilestoneFragment = gql`
  fragment ProjectMilestoneData on ProjectMilestone {
    id
    name
    date
    description
    revenue
  }
`;

export default ProjectMilestoneFragment;