import { gql } from '@apollo/client';
import ConsultantGroupBaseFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupBaseFragment';

const ConsultantGroupFragment = gql`
  fragment ConsultantGroupData on ConsultantGroup {
    ...ConsultantGroupBaseData
    sortOrder
    description
  }
  ${ConsultantGroupBaseFragment}
`;

export default ConsultantGroupFragment;
