import { Nullable, UnknownObject } from 'Shared/types/utils';

type StorageItem = string | number | UnknownObject | boolean;
type ItemWithExpiry = {
  data: StorageItem;
  expiry: number;
};

export interface IAppStorage {
  storeItem: (name: string, data: StorageItem) => void;
  storeWithExpiry: (name: string, data: StorageItem, ttl: number) => void;
  getItem: (name: string) => Nullable<string>;
  getWithExpiry: (name: string) => Nullable<StorageItem>;
  removeItem: (name: string) => void;
  clear: () => void;
}

export class AppStorage implements IAppStorage {
  constructor(private readonly storage: Storage) {}

  storeItem(name: string, data: StorageItem): void {
    this.storage.setItem(name, typeof data === 'object' ? JSON.stringify(data) : String(data));
  }

  /**
   * Stores data with given expiration date
   * Use getWithExpiry to retrieve such item
   * @param name item key in storage
   * @param data data to be stored
   * @param ttl time to live in seconds
   */
  storeWithExpiry(name: string, data: StorageItem, ttl: number): void {
    const item: ItemWithExpiry = {
      data,
      expiry: new Date().getTime() + ttl,
    };
    this.storeItem(name, item);
  }

  getWithExpiry(itemName: string): Nullable<StorageItem> {
    const item = this.getItem(itemName);
    if (!item) return null;

    let itemData: ItemWithExpiry;
    try {
      itemData = JSON.parse(item);
    } catch (error) {
      return item;
    }
    if (!itemData?.expiry) return item;

    if (new Date().getTime() > itemData.expiry) {
      this.storage.removeItem(itemName);
      return null;
    }
    return itemData.data;
  }

  removeItem(name: string): void {
    this.storage.removeItem(name);
  }

  getItem(name: string): Nullable<string> {
    return this.storage.getItem(name) || null;
  }

  clear(): void {
    this.storage.clear();
  }
}
