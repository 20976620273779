import { useCallback } from 'react';
import { LocaleMessages, SupportedLocales } from 'Shared/types/Intl';
import { DEFAULT_LOCALE } from 'Shared/constants';
import { makeVar, useReactiveVar } from '@apollo/client';

interface LocaleState {
  activeLocale: SupportedLocales;
  messages?: LocaleMessages;
}

interface UseLocaleReturnValue extends LocaleState {
  setLocale: (locale?: SupportedLocales) => Promise<void>;
}

const localeStateVar = makeVar<LocaleState>({
  activeLocale: DEFAULT_LOCALE,
  messages: undefined,
});

function importLocaleMessages(locale: SupportedLocales): Promise<any> {
  switch (locale) {
    case 'en':
      return import('Shared/locales/compiled/en.json');
    default:
      return import('Shared/locales/compiled/en.json');
  }
}

const useLocale = (): UseLocaleReturnValue => {
  const { activeLocale, messages } = useReactiveVar(localeStateVar);

  const setLocale = useCallback(async (
    locale: SupportedLocales = DEFAULT_LOCALE,
  ): Promise<void> => {
    const localeMessages = await importLocaleMessages(locale);
    localeStateVar({
      ...localeStateVar,
      activeLocale: locale,
      messages: localeMessages,
    });
  }, []);

  return {
    activeLocale,
    setLocale,
    messages,
  };
};

export default useLocale;
