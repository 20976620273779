import {
  InvalidateCacheConfig,
  VariablesGetterProps,
  VariablesGetterReturnType,
} from 'Shared/api/links/InvalidateCacheLink';
import {
  CreateProjectMutationVariables,
  LoseProjectMutationVariables,
  UpdateProjectMutationVariables,
  SetupProjectOnboardingMutationVariables,
  UpdateProjectPeriodMutationVariables,
  UpdateProjectBudgetMutationVariables,
} from '__generated__/graphql/types';
import { QueryName, MutationName } from 'Shared/types/api';

type VariablesProps<TMutationVariables, TContext = any,
> = VariablesGetterProps<TMutationVariables, TContext>;

export type RemoveProjectContext = {
  meta: {
    scenarioSlug: string;
  }
};

const INVALIDATION_DELAY = 600;

const projectInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  createProject: {
    delay: INVALIDATION_DELAY,
    invalidateOperations: [
      {
        operationName: 'workspaceClients',
      },
      { operationName: 'projectsOverview' },
      {
        operationName: 'projectsOverview',
        variables: ({ variables }: VariablesProps<CreateProjectMutationVariables>) => ({
          clientId: variables.projectInfo.clientId,
        }),
      },
      {
        operationName: 'clientProjects',
        variables: ({ variables }: VariablesProps<CreateProjectMutationVariables>) => ({
          clientId: variables.projectInfo.clientId,
        }),
      },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
    ],
  },
  loseProject: {
    invalidateOperations: [
      {
        operationName: 'workspaceClients',
      },
      { operationName: 'projectsOverview', variables: () => ({}) },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
      { operationName: 'consultantProjectsOverview', variables: () => ({}) },
      { operationName: 'consultant', variables: () => ({}) },
    ],
  },
  closeProject: {
    extend: 'loseProject',
    invalidateOperations: [
      {
        operationName: 'workspaceClients',
      },
    ],
  },
  removeProject: {
    extend: 'loseProject',
    invalidateOperations: [
      {
        operationName: 'workspaceClients',
      },
      {
        operationName: 'consultantProjectRoles'
      },
    ],
  },
  updateProject: {
    extend: 'updateProjectDiscount',
    invalidateOperations: [
      {
        operationName: 'projectRoles',
        variables: ({
          variables,
        }: VariablesProps<UpdateProjectMutationVariables>): VariablesGetterReturnType => {
          const { projectId, scenarioSlug } = variables;
          return { projectId, scenarioSlug };
        },
      },
    ],
  },
  updateProjectStage: {
    extend: 'loseProject',
    invalidateOperations: [
      {
        operationName: 'workspaceClients',
      },
      {
        operationName: 'projectScenarios',
        variables: ({
          variables,
        }: VariablesProps<UpdateProjectMutationVariables,
        LoseProjectMutationVariables>): VariablesGetterReturnType => {
          const { projectId } = variables;
          return { projectId };
        },
      },
      {
        operationName: 'workspaceProject',
        variables: () => ({}),
      }
    ],
  },
  setupProjectOnboarding: {
    invalidateOperations: [
      {
        operationName: 'projectScenarios',
        variables: ({
          variables,
        }: VariablesProps<SetupProjectOnboardingMutationVariables>): VariablesGetterReturnType => {
          const { projectId } = variables;
          return { projectId };
        },
      },
    ],
  },
  updateProjectWinProbability: {
    extend: 'updateProjectDiscount',
    invalidateOperations: [
      {
        operationName: 'workspaceProject',
        variables: () => ({}),
      }
    ],
  },
  updateProjectDiscount: {
    invalidateOperations: [
      // to simplify things, let's invalidate all possibly related queries
      { operationName: 'workspaceClients', variables: () => ({}) },
      { operationName: 'workspaceClient', variables: () => ({}) },
      { operationName: 'clientProjects', variables: () => ({}) },
      { operationName: 'workspaceProjects', variables: () => ({}) },
      { operationName: 'projectsOverview' },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
      { operationName: 'projectTimesheetPlanner', variables: () => ({}) },
      { operationName: 'consultantProjectsOverview', variables: () => ({}) },
    ]
  },
  updateProjectPeriod: {
    invalidateOperations: [
      {
        operationName: 'projectTimesheetPlanner',
        variables: ({
          variables,
        }: VariablesProps<UpdateProjectPeriodMutationVariables>): VariablesGetterReturnType => {
          const { projectId } = variables;
          return { projectId };
        },
      },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
      {
        operationName: 'workspaceClients',
      },
    ],
  },
  updateProjectBudget: {
    invalidateOperations: [
      {
        operationName: 'budgetOverview',
        variables: ({ variables }: VariablesProps<
        UpdateProjectBudgetMutationVariables | UpdateProjectBudgetMutationVariables>):
        VariablesGetterReturnType => {
          const { projectId, scenarioSlug } = variables;
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
    ],
  },
  updateProjectType: {
    extend: 'updateProjectBudget',
  },
};

export default projectInvalidationConfig;
