import { gql } from '@apollo/client';

const WorkspaceSettingsFragment = gql`
  fragment WorkspaceSettingsData on WorkspaceSettings {
    id
    prefix
    emailHeader
    emailFooter
    defaultWinProbability
    defaultTimesheetsManualCollection
    logoUrl
    invoiceHeaderTextColor
    invoiceBodyTextColor
    invoiceAdditionalInfo
    invoiceFooter
    purchaseOrderAdditionalInfo
    defaultMarketplaceMargin
    marketplaceRequestContact
    timesheetsAutoApproval
    sendEngagementMailForNoPlan
    engagementSummaryType
    isCostRateVisibleCP
    loadEmployeeCostRateFrom
    canEditEmployeeRoleCostRate
    opportunityBoardTitle
    opportunityBoardDescription
    opportunityBoardLogoUrl
    opportunityBoardActiveBtnColor
    opportunityBoardCustomLinkTitle
    opportunityBoardCustomLink
    opportunityBoardLinkedin
    opportunityBoardShowTitleSection
    opportunityBoardBackgroundColor
    opportunityBoardTextColor
    opportunityBoardBackgroundImageUrl
    defaultOpenForBooking
    openForBookingConsultantTypes
    actualizeTimesheetsAtDayOfWeek
  }
`;

export default WorkspaceSettingsFragment;
