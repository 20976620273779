import { gql } from '@apollo/client';
import CurrencyRateFragment from 'Shared/api/fragments/Currency/CurrencyRateFragment';

const WorkspaceFragment = gql`
  fragment WorkspaceData on AvailableWorkspace {
    id
    name
    identifier
    accessLevel
    position
    currency
    features
    portals
    clientId
    consultantId
    defaultWinProbability
    purchaseOrderAdditionalInfo
    invoiceAdditionalInfo
    invoiceFooter
    invoiceHeaderTextColor
    invoiceBodyTextColor
    defaultTimesheetsManualCollection
    defaultMarketplaceMargin
    marketplaceRequestContact
    timesheetsAutoApproval
    sendEngagementMailForNoPlan
    engagementSummaryType
    isCostRateVisibleCP
    logoUrl
    currencyRates {
      ...CurrencyRateData
    }
    availableCurrencies
    defaultOpenForBooking
    active @client
  }
  ${CurrencyRateFragment}
`;

export default WorkspaceFragment;
