import { gql } from '@apollo/client';
import ExpenseCategoryOverviewFragment from './ExpenseCategoryOverviewFragment';

const ExpensesOverviewFragment = gql`
  fragment ExpensesOverviewData on ExpensesOverview {
    id
    totalCost
    totalClientCharge
    totalGrossMargin
    grossMarginPct
    expenseCategories {
      ...ExpenseCategoryOverviewData
    }
  }
  ${ExpenseCategoryOverviewFragment}
`;

export default ExpensesOverviewFragment;
