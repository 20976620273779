import { WorkspaceDataFragment, Scalars } from '__generated__/graphql/types';
import { ACTIVE_WORKSPACE_ID_STORAGE_NAME } from 'Shared/constants/index';
import { IAppStorage } from 'Shared/services/storage';

export interface IActiveWorkspaceStorage {
  id: string | undefined;
  find: (workspaces: WorkspaceDataFragment[]) => WorkspaceDataFragment;
  storeId: (id: Scalars['ID']) => void;
  clear: () => void;
}

export class ActiveWorkspaceStorage implements IActiveWorkspaceStorage {
  constructor(private readonly storage: IAppStorage) {}

  get id(): string | undefined {
    return this.storage.getItem(ACTIVE_WORKSPACE_ID_STORAGE_NAME) || undefined;
  }

  find(workspaces: WorkspaceDataFragment[]): ReturnType<IActiveWorkspaceStorage['find']> {
    return workspaces.find((workspace) => workspace.id === this.id) || workspaces[0];
  }

  storeId(id: Scalars['ID']): void {
    this.storage.storeItem(ACTIVE_WORKSPACE_ID_STORAGE_NAME, id);
  }

  clear(): void {
    this.storage.removeItem(ACTIVE_WORKSPACE_ID_STORAGE_NAME);
  }
}
