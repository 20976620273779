import { gql } from '@apollo/client';
import WorkspaceFragment from '../Workspace/WorkspaceFragment';

const AuthPayloadFragment = gql`
  fragment AuthPayloadData on AuthPayload {
    token
    userId
    workspaces { ...WorkspaceData }
  }
  ${WorkspaceFragment}
`;

export default AuthPayloadFragment;
