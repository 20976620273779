import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';
import WorkspaceContractTemplateFragment from '../WorkspaceContractTemplate/WorkspaceContractTemplateFragment';
import ConsultantContractFragment from './ConsultantContractFragment';

const ConsultantContractProjectFragment = gql`
  fragment ConsultantContractProjectData on Project {
    id
    name
    visibleToClient
    scenario {
      ...ProjectScenarioRequiredData
    }
  }
  ${ProjectScenarioRequiredDataFragment}
`;

const ConsultantContractsTableFragment = gql`
  fragment ConsultantContractsTableData on ConsultantContract {
    ...ConsultantContractData
    project {
      ...ConsultantContractProjectData
    }
    template {
      ...WorkspaceContractTemplateData
    }
  }
  ${ConsultantContractFragment}
  ${ConsultantContractProjectFragment}
  ${WorkspaceContractTemplateFragment}
`;

export default ConsultantContractsTableFragment;
