import React, { FC, ReactElement } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { DrawerProps } from 'antd/lib/drawer';
import { Space, Title } from 'Shared/ui';
import theme from 'Shared/constants/theme';
import { ButtonProps, PrimaryButton, SecondaryButton } from 'Shared/ui/Button';

const DefaultFooterRigthBox = styled.div({
  textAlign: 'right',
});

const DefaultFooterWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface DefaultDrawerFooterLayoutProps {
  left?: ReactElement;
  right: ReactElement;
}

export const DefaultDrawerFooterLayout: FC<DefaultDrawerFooterLayoutProps> = ({
  left, right,
}) => (
  <DefaultFooterWrap>
    <Space size="small">
      {left || <>&nbsp;</>}
    </Space>
    <DefaultFooterRigthBox>
      <Space size="small">
        {right}
      </Space>
    </DefaultFooterRigthBox>
  </DefaultFooterWrap>
);

export interface DefaultFooterProps {
  okBtnTitle: string;
  okBtnProps?: ButtonProps;
  cancelBtnTitle?: string;
  cancelBtnProps?: ButtonProps;
  extraBtnTitle?: string;
  extraBtnProps?: ButtonProps;
}

export const DefaultDrawerFooter: FC<DefaultFooterProps> = ({
  okBtnTitle, okBtnProps,
  cancelBtnTitle, cancelBtnProps,
  extraBtnTitle, extraBtnProps,
}) => (
  <DefaultDrawerFooterLayout
    left={
      extraBtnTitle ? (
        <SecondaryButton
          {...extraBtnProps}
        >
          {extraBtnTitle}
        </SecondaryButton>
      ) : (
        <>&nbsp;</>
      )
    }
    right={(
      <>
        {cancelBtnTitle && (
          <SecondaryButton {...cancelBtnProps}>
            {cancelBtnTitle}
          </SecondaryButton>
        )}
        {okBtnTitle && (
          <PrimaryButton {...okBtnProps}>
            {okBtnTitle}
          </PrimaryButton>
        )}
      </>
    )}
  />
);

const DefaultTitle: FC<unknown> = ({ children }) => (
  <Title
    level={4}
    style={{
      lineHeight: '23px',
    }}
  >
    {children}
  </Title>
);

const DefaultDrawer: FC<DrawerProps> = ({
  title,
  footer,
  width = theme.drawer.width.default,
  ...props
}) => (
  <Drawer
    width={width}
    placement="right"
    closable
    {...props}
    title={<DefaultTitle>{title}</DefaultTitle>}
    footer={footer}
  />
);

export { Drawer, DefaultDrawer };
