import styled from 'styled-components';

export const TextEllipsis = styled.div({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const PreFormat = styled.pre({
  '&&': {
    background: 'transparent',
    border: 0,
  },
});
