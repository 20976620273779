/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { ContractType, CurrencyCode, OnboardingRequirements } from '__generated__/graphql/types';

export const NPS_MIN_VALUE = 0;
export const NPS_MAX_VALUE = 10;
export const DEFAULT_PROJECT_SCENARIO_SLUG = 'main';
export const CONTRACT_TYPES_WITH_PROJECT = [ContractType.Da, ContractType.Po, ContractType.Sow];
export const MAX_WORKING_DAYS_PER_WEEK = 5;
export const MIN_WORKING_DAYS_PER_WEEK = 0;
export const MAX_WORKING_HOURS_PER_DAY = 8;
export const MIN_WORKING_HOURS_PER_DAY = 0;
export const MAX_WORKING_HOURS_PER_DAY_LIMIT = 24;
export const MAX_WORKING_DAYS_PER_DAY_LIMIT = 3; // https://www.notion.so/riverflex/Financial-Planner-Tech-06da5ddfddf545618a82734cc95eb33b
export const WORKING_HOURS_PER_WEEK_LIMIT = 7 * MAX_WORKING_HOURS_PER_DAY_LIMIT; // 7d * 24h = 168h
export const MAX_WORKING_HOURS_PER_WEEK = MAX_WORKING_HOURS_PER_DAY * MAX_WORKING_DAYS_PER_WEEK; // 40h
export const WORKING_DAYS_PER_WEEK_LIMIT = WORKING_HOURS_PER_WEEK_LIMIT / MAX_WORKING_HOURS_PER_DAY; // 168 / 8 = 21
export const BUSINESS_DAYS_PER_WEEK = 5;
export const DAYS_A_WEEK = 7;
export const TOTAL_WORK_UNITS_DECIMAL_POINT = 2;
export const BILLING_CURRENCY_CODE = CurrencyCode.Gbp;

export const CLIENT_PAGE_DEFAULT_TAB = 'projects';
export const BILLING_PAGE_DEFAULT_TAB = 'projects';
export const PROJECT_PAGE_DEFAULT_TAB = 'consultants';
export const CONSULTANT_PAGE_DEFAULT_TAB = 'projects';
export const PERF_MANAGEMENT_PAGE_DEFAULT_TAB = 'activity-templates';
export const SETTINGS_PAGE_DEFAULT_TAB = 'profile';
export const CONSULTANTS_PAGE_DEFAULT_TAB = 'yourConsultants';
export const HOME_PAGE_ROUTE = 'clients';
export const JOBS_PAGE_DEFAULT_TAB = 'active';
export const JOBS_EDITVIEW_PAGE_DEFAULT_TAB = 'JobDescription';
export const CONSULTANT_PAGE_PATH = 'consultant/:id/:tab';
export const SETTINGS_PAGE_PATH = 'settings/:tab';

export const LOGIN_PAGE_PATH = 'login';
export const REGISTRATION_PAGE_PATH = 'registration';

export const CONSULTANT_ONBOARDING_REQUIREMENTS_SORT: Record<OnboardingRequirements, number> = {
  PlatformSignup: 0,
  BusinessInformationSet: 1,
  BusinessInformationVerified: 2,
  ContractSignedMNDA: 3,
  ContractSignedSA: 4,
  ContractSignedDA: 5,
};

// Surveys
export const REQUEST_NEW_CONSULTANT_SURVEY_ID = process.env.REACT_APP_REQUEST_NEW_CONSULTANT_SURVEY_ID as string;
export const REQUEST_EXISTING_CONSULTANT_SURVEY_ID = process.env.REACT_APP_REQUEST_EXISTING_CONSULTANT_SURVEY_ID as string;
export const REQUEST_RIVERFLEX_CONSULTANT_SURVEY_ID = process.env.REACT_APP_REQUEST_RIVERFLEX_CONSULTANT_SURVEY_ID as string;
