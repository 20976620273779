import { gql } from '@apollo/client';

const InvoiceRoleExtraDataFragment = gql`
  fragment InvoiceRoleExtraDataData on InvoiceRoleData {
      weekStartDate
      weekEndDate
      uninvoicedActuals
      type
    }
`;

export default InvoiceRoleExtraDataFragment;
