import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generateCalendar, { CalendarProps as GenerateCalendarProps } from 'antd/lib/calendar/generateCalendar';

const GeneratedCalendar = generateCalendar<Date>(dateFnsGenerateConfig);
type CalendarProps = GenerateCalendarProps<Date>;

export {
  GeneratedCalendar as Calendar,
};

export type { CalendarProps };