import { gql } from '@apollo/client';
import UserFragment from 'Shared/api/fragments/User/UserFragment';
import {
  ProjectFragment, ProjectsOverviewFragment,
  ConsultantBaseFragment, ProjectRoleFragment, ProjectDeliverableFragment,
  ConsultantContractFragment,
  ClientContractFragment, ProjectsFragment,
  ProjectTimesheetPlannerBundleFragment, ProjectScenarioFragment,
  ConsultantToBeOnboardedFragment,
  ProjectAutocompleteFragment,
  ProjectExpenseFragment,
  ExpensesOverviewFragment,
  ProjectRolesOverviewFragment,
  BudgetOverviewFragment,
  NoteBaseFragment,
  ProjectRoleDayWorkPlanFragment,
  ProjectMilestoneFragment,
} from 'WorkspacePortal/api/fragments';

export const GET_WORKSPACE_PROJECTS = gql`
    query workspaceProjects($filter: ProjectFilterInput, $sort: ProjectSortInput) {
      workspaceProjects(sort: $sort, filter: $filter) {
        ...ProjectsData
      }
    }
    ${ProjectsFragment}
`;

export const GET_WORKSPACE_PROJECTS_AUTOCOMPLETE = gql`
  query workspaceProjectsAutocomplete($filter: ProjectFilterInput, $sort: ProjectSortInput) {
    workspaceProjects(sort: $sort, filter: $filter) {
      ...ProjectAutocompleteData
    }
  }
  ${ProjectAutocompleteFragment}
`;

export const GET_USERS_ASSIGNED_TO_WORKSPACE_PROJECTS = gql`
    query usersAssignedToWorkspaceProjects {
        usersAssignedToWorkspaceProjects {
          ...UserData
        }
    }
    ${UserFragment}
`;

export const PROJECTS_OVERVIEW = gql`
  query projectsOverview($clientId: ID) {
    projectsOverview(clientId: $clientId) {
        ...ProjectsOverviewData
    }
  }
  ${ProjectsOverviewFragment}
`;

export const GET_WORKSPACE_PROJECT = gql`
  query workspaceProject($projectId: ID!, $scenarioSlug: String) {
    workspaceProject(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ProjectData
    }
  }
  ${ProjectFragment}
`;

export const GET_PROJECT_ROLES = gql`
  query projectRoles($projectId: ID!, $scenarioSlug: String, $filter: ProjectRoleFilterInput, $sort: ProjectRoleSortInput) {
    projectRoles(projectId: $projectId, scenarioSlug: $scenarioSlug, filter: $filter, sort: $sort) {
      ...ProjectRoleData
    }
  }
  ${ProjectRoleFragment}
`;

export const GET_PROJECT_ROLE = gql`
  query projectRole($projectRoleId: ID!) {
    projectRole(projectRoleId: $projectRoleId) {
      ...ProjectRoleData
    }
  }
  ${ProjectRoleFragment}
`;

export const IS_PROJECT_ROLE_REASSIGNABLE = gql`
  query isProjectRoleReassignable($projectRoleId: ID!) {
    isProjectRoleReassignable(projectRoleId: $projectRoleId)
  }
`;

export const GET_CONSULTANTS_ASSIGNED_TO_PROJECT = gql`
  query consultantsAssignedToProject($projectId: ID!, $scenarioSlug: String) {
    consultantsAssignedToProject(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ConsultantBaseData
    }
  }
  ${ConsultantBaseFragment}
`;

export const GET_PROJECT_DELIVERABLES = gql`
  query projectDeliverables($projectId: ID!, $scenarioSlug: String, $filter: ProjectDeliverableFilterInput, $sort: ProjectDeliverableSortInput) {
    projectDeliverables(projectId: $projectId, scenarioSlug: $scenarioSlug, filter: $filter, sort: $sort) {
      ...ProjectDeliverableData
    }
  }
  ${ProjectDeliverableFragment}
`;

export const GET_PROJECT_DELIVERABLE = gql`
  query projectDeliverable($deliverableId: ID!) {
    projectDeliverable(deliverableId: $deliverableId) {
      ...ProjectDeliverableData
    }
  }
  ${ProjectDeliverableFragment}
`;

export const GET_PROJECT_CONSULTANT_CONTRACTS = gql`
  query projectConsultantContracts($projectId: ID!, $filter: ConsultantContractFilterInput, $sort: ConsultantContractSortInput) {
    projectConsultantContracts(projectId: $projectId, filter: $filter, sort: $sort) {
      ...ConsultantContractData
    }
  }
  ${ConsultantContractFragment}
`;

export const GET_PROJECT_CLIENT_CONTRACTS = gql`
  query projectClientContracts($projectId: ID!, $filter: ClientContractFilterInput, $sort: ClientContractSortInput) {
    projectClientContracts(projectId: $projectId, filter: $filter, sort: $sort) {
      ...ClientContractData
    }
  }
  ${ClientContractFragment}
`;

export const GET_CONSULTANTS_TO_BE_ONBOARDED = gql`
  query consultantsToBeOnboarded($projectId: ID!) {
    consultantsToBeOnboarded(projectId: $projectId) {
      ...ConsultantToBeOnboardedData
    }
  }
  ${ConsultantToBeOnboardedFragment}
`;

export const GET_PROJECT_TIMESHEET_PLANNER = gql`
  query projectTimesheetPlanner($projectId: ID!, $scenarioSlug: String) {
    projectTimesheetPlanner(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ProjectTimesheetPlannerBundleData
    }
  }
  ${ProjectTimesheetPlannerBundleFragment}
`;

export const PROJECT_PERIOD_AFFECTS_TIMESHEET = gql`
  query projectPeriodAffectsTimesheet($projectId: ID!, $scenarioSlug: String, $startDate: DateTime, $endDate: DateTime) {
    projectPeriodAffectsTimesheet(projectId: $projectId, scenarioSlug: $scenarioSlug, startDate: $startDate, endDate: $endDate)
  }
`;

export const PROJECT_ROLE_PERIOD_AFFECTS_TIMESHEET = gql`
  query projectRolePeriodAffectsTimesheet($roleId: ID!, $startDate: DateTime, $endDate: DateTime) {
    projectRolePeriodAffectsTimesheet(roleId: $roleId, startDate: $startDate, endDate: $endDate)
  }
`;

export const GET_PROJECT_SCENARIOS = gql`
  query projectScenarios($projectId: ID!) {
    projectScenarios(projectId: $projectId) {
      ...ProjectScenarioData
    }
  }
  ${ProjectScenarioFragment}
`;

export const GET_PROJECT_SCENARIO = gql`
  query projectScenario($scenarioId: ID!) {
    projectScenario(scenarioId: $scenarioId) {
      ...ProjectScenarioData
    }
  }
  ${ProjectScenarioFragment}
`;

export const GET_PROJECT_EXPENSES = gql`
  query projectExpenses($projectId: ID!, $scenarioSlug: String!, $sort: ProjectExpenseSortInput) {
    projectExpenses(projectId: $projectId, scenarioSlug: $scenarioSlug, sort: $sort) {
      ...ProjectExpenseData
    }
  }
  ${ProjectExpenseFragment}
`;

export const GET_PROJECT_EXPENSES_OVERVIEW = gql`
  query expensesOverview($projectId: ID!, $scenarioSlug: String!) {
    expensesOverview(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ExpensesOverviewData
    }
  }
  ${ExpensesOverviewFragment}
`;

export const GET_PROJECT_ROLES_OVERVIEW = gql`
  query projectRolesOverview($projectId: ID!, $scenarioSlug: String!) {
    projectRolesOverview(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ProjectRolesOverviewData
    }
  }
  ${ProjectRolesOverviewFragment}
`;

export const GET_BUDGET_OVERVIEW = gql`
  query budgetOverview($projectId: ID!, $scenarioSlug: String!) {
    budgetOverview(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...BudgetOverviewData
    }
  }
  ${BudgetOverviewFragment}
`;

export const GET_PROJECT_NOTES = gql`
  query projectNotes($projectId: ID!) {
    projectNotes(projectId: $projectId) {
      ...NoteBaseData
    }
  }
  ${NoteBaseFragment}
`;

export const GET_PROJECT_ROLE_WORK_PLAN = gql`
  query projectRoleWorkPlan($roleId: ID!) {
    projectRoleWorkPlan(roleId: $roleId) {
      ...ProjectRoleDayWorkPlanData
    }
  }
  ${ProjectRoleDayWorkPlanFragment}
`;

export const HAS_INVOICED_ACTUALS = gql`
  query hasInvoicedActuals($roleId: ID!, $periodStartDate: DateTime!) {
    hasInvoicedActuals(roleId: $roleId, periodStartDate: $periodStartDate)
  }
`;

export const GET_PROJECT_MILESTONES = gql`
  query projectMilestones($projectId: ID!, $scenarioSlug: String!) {
    projectMilestones(projectId: $projectId, scenarioSlug: $scenarioSlug) {
      ...ProjectMilestoneData
    }
  }
  ${ProjectMilestoneFragment}
`;

export const GET_PROJECT_MILESTONE = gql`
  query projectMilestone($milestoneId: ID!) {
    projectMilestone(milestoneId: $milestoneId) {
      ...ProjectMilestoneData
    }
  }
  ${ProjectMilestoneFragment}
`;