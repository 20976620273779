import { gql } from '@apollo/client';
import CurrencyRateFragment from 'Shared/api/fragments/Currency/CurrencyRateFragment';
import ProjectRoleOnboardingStateFragment from 'WorkspacePortal/api/fragments/ProjectRole/ProjectRoleOnboardingStateFragment';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';

const ConsultantProjectRoleProjectFragment = gql`
  fragment ConsultantProjectRoleProjectData on Project {
    id
    name
    status
    stage
    client {
      id
      name
    }
    currency
    currencyRates {
      ...CurrencyRateData
    }
    scenario {
      ...ProjectScenarioRequiredData
    }
    visibleToClient
  }
  ${ProjectScenarioRequiredDataFragment}
  ${CurrencyRateFragment}
`;

const ConsultantProjectRoleFragment = gql`
  fragment ConsultantProjectRoleData on ProjectRole {
    id
    project {
      ...ConsultantProjectRoleProjectData
    }
    role
    units
    unitsPerWeek
    costRate
    currency
    clientRate
    grossMargin
    revenue
    unitType
    onboardingState {
      ...ProjectRoleOnboardingStateData
    }
    actualUnits
    plannedUnits
  }
  ${ConsultantProjectRoleProjectFragment}
  ${ProjectRoleOnboardingStateFragment}
`;

export default ConsultantProjectRoleFragment;
