import { gql } from '@apollo/client';
import ConsultantBaseFragment from 'Shared/api/fragments/Consultant/ConsultantBaseFragment';
import WorkspaceContractTemplateFragment from 'WorkspacePortal/api/fragments/WorkspaceContractTemplate/WorkspaceContractTemplateFragment';

const ConsultantContractFragment = gql`
  fragment ConsultantContractData on ConsultantContract {
    id
    name
    type
    status
    docusignStage
    startDate
    endDate
    createdDate
    viewUrl
    downloadUrl
    metaData
    source
    isVoidable
    baseContract {
      id
    }
    consultant {
      ...ConsultantBaseData
    }
    identifier
    template {
      ...WorkspaceContractTemplateData
    }
  }
  ${ConsultantBaseFragment}
  ${WorkspaceContractTemplateFragment}
`;

export default ConsultantContractFragment;
