import { Input, InputNumber, InputNumberProps, InputRef } from 'antd';
import {
  InputProps, PasswordProps, TextAreaProps,
} from 'antd/lib/input';
import theme from 'Shared/constants/theme';
import styled from 'styled-components';

const {
  Group, Search, Password, TextArea,
} = Input;

// Input looking like a textbox without borders
// Borders are shown when user hovers over it or focus on it
const TextInput = styled(Input)({
  '.ant-input-prefix': {
    marginRight: '2px',
    transition: 'color 0.2s ease',
  },
  '&&, & .ant-input': {
    '&:not(:hover)': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: 'inherit',
    },
    '&[readonly]': {
      borderColor: 'transparent',
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '&.has-error': {
      borderColor: theme.colors.error.base,
    },
    '&.closed, &.closed:hover': { // greyed out background
      backgroundColor: theme.colors.neutral[300],
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
  '&.ant-input-affix-wrapper-focused .ant-input-prefix': {
    color: 'lightgrey',
  },
  '&.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):not(.closed):hover': {
    backgroundColor: theme.colors.white.base,
  },
});

export {
  Input,
  TextInput,
  Password,
  TextArea,
  InputNumber,
  Group as InputGroup,
  Search as InputSearch,
};

export type {
  InputProps,
  PasswordProps,
  TextAreaProps,
  InputNumberProps,
  InputRef,
};
