import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

const {
  Meta: CardMeta,
  Grid: CardGrid,
} = Card;

export {
  Card,
  CardMeta,
  CardGrid,
};

export type { CardProps };
