import { gql } from '@apollo/client';

const WorkspaceLocationFragment = gql`
  fragment WorkspaceLocationData on WorkspaceLocation {
    id
    name
    sortOrder
  }
`;

export default WorkspaceLocationFragment;
