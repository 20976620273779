import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { History } from 'history';

import {
  AdminLoginMutation,
  AdminLoginMutationVariables,
} from '__generated__/graphql/types';
import { IAuthTokenStorage } from 'Shared/services/auth/AuthTokenStorage';
import { getAdminPortalLoginPagePath } from 'Shared/utils/routing/getLoginPagePath';
import { ADMIN_PORTAL_ROOT_PATH } from 'AdminPortal/constants';
import AdminPortalActiveWorkspaceStorage from '../AdminPortalActiveWorkspaceStorage';
import { ADMIN_PORTAL_LOGIN } from 'AdminPortal/api/mutations/auth';

type LoginOptions = {
  email: string;
  password: string;
  rememberLogin?: boolean;
};

type IClient = ApolloClient<NormalizedCacheObject | any>;

export default class AdminPortalAuthService {
  constructor(
    private client: IClient,
    private readonly adminWorkspaceStorage: AdminPortalActiveWorkspaceStorage,
    private readonly authTokenStorage: IAuthTokenStorage,
    private readonly history: History,
  ) {}

  async login({
    email, password, rememberLogin,
  }: LoginOptions): Promise<void> {
    const response = await this.client.mutate<
    AdminLoginMutation, AdminLoginMutationVariables>({
      mutation: ADMIN_PORTAL_LOGIN,
      variables: { email, password },
    });
    if (!response?.data) return;
    const { token } = response.data.adminLogin;
    this.authTokenStorage.storeToken(token, !!rememberLogin);
    this.history.push(`/${ADMIN_PORTAL_ROOT_PATH}`);
  }

  async logout(): Promise<void> {
    this.authTokenStorage.deleteToken();
    await this.client.clearStore();
    this.client.cache.gc();
    this.adminWorkspaceStorage.clear();
    this.history.push(getAdminPortalLoginPagePath());
  }

  hardLogout(): void {
    this.authTokenStorage.deleteToken();
    window.location.replace(`/${getAdminPortalLoginPagePath()}`);
  }
}
