import { gql } from '@apollo/client';
import WorkspaceClientsFragment from 'WorkspacePortal/api/fragments/Client/WorkspaceClientsFragment';

const ClientFragment = gql`
  fragment ClientData on WorkspaceClient {
    ...WorkspaceClientsData
    website
    phoneNumber
    city
    legalName
    registrationNumber
    description
  }
  ${WorkspaceClientsFragment}
`;

export default ClientFragment;
