import React, { FC, useEffect } from 'react';
import { IntlProvider, IntlConfig } from 'react-intl';

import { DEFAULT_LOCALE } from 'Shared/constants/index';
import useLocale from './useLocale';

// HTML tags supported in locales
// Please add any other tags when you would need them
const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  b: (chunks) => <b>{chunks}</b>,
  strong: (chunks) => <strong>{chunks}</strong>,
  br: () => <br />,
  span: (chunks) => <span>{chunks}</span>,
};

const AppIntlProvider: FC<unknown> = ({ children }) => {
  const { activeLocale, setLocale, messages } = useLocale();

  useEffect(() => {
    setLocale(DEFAULT_LOCALE);
  }, [setLocale]);

  if (!messages) {
    return <></>;
  }

  return (
    <IntlProvider
      messages={messages}
      locale={activeLocale}
      defaultLocale={DEFAULT_LOCALE}
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
