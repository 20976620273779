import { gql } from '@apollo/client';
import SubscriptionPackageFragment from 'Shared/api/fragments/SubscriptionPackage/SubscriptionPackageFragment';

const WorkspaceInvoiceHistoricalRecordFragment = gql`
  fragment WorkspaceInvoiceHistoricalRecordData on WorkspaceInvoiceHistoricalRecord {
    id
    date
    package {
      ...SubscriptionPackageData
    }
    amount
    countActiveConsultants
    credits
    dated
    #countActiveProjects
    #countTotalClients
    #countSharedCollectives
  }
  ${SubscriptionPackageFragment}
`;

export default WorkspaceInvoiceHistoricalRecordFragment;
