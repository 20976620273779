import {
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
  InMemoryCache,
  ApolloError,
} from '@apollo/client';
import { Modifier, Modifiers } from '@apollo/client/cache/core/types/common';

import { Fragment, QueryName } from 'Shared/types/api';
import { ILogger } from 'Shared/services/logger';
import { Scalars } from '__generated__/graphql/types';
import { removeFragmentByFieldValues } from 'Shared/api/cache/cache';
import { isOperationMutation } from 'Shared/api/helpers';

// Remove fragment from collection by its id
interface Config1 {
  collectionsToBeUpdated: QueryName[];
  entityId: Scalars['ID'];
  fieldValues?: undefined;
}

// Remove fragment from store by any combination fo fieldValues
interface Config2 {
  collectionsToBeUpdated?: undefined;
  entityId?: undefined
  fieldValues: Record<string, number | string>; // removes entity by any entity fields values
  fragmentTypeName: Fragment;
}

type Config = Config1 | Config2;

export interface RemoveEntityFromCollectionContext {
  removeEntityFromCollection: Config;
}

/**
 * Implemented this link instead of useDeleteEntity hook
 * Usage
 * import { RemoveEntityFromCollectionContext } from 'Shared/api/links/RemoveEntityFromCollectionLink';
 * const context: RemoveEntityFromCollectionContext = {
    removeEntityFromCollection: {
      collectionsToBeUpdated: ['collectionName'],
      entityId: 'apiEntityId'
    },
  };
 * const [delete] = useGeneratedGraphqlMutation()
 * delete({
 *  variable: { name: '123' },
 *  context: context
 * });
 */
class RemoveEntityFromCollectionLink extends ApolloLink {
  constructor(
    readonly cache: InMemoryCache,
    readonly logger: ILogger,
  ) {
    super();
  }

  // eslint-disable-next-line class-methods-use-this
  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    const context = operation.getContext() as RemoveEntityFromCollectionContext;

    return forward(operation).map((response) => {
      if (response.errors || !response.data) return response;

      const isMutation = isOperationMutation(operation);
      const config = context.removeEntityFromCollection;
      if (!isMutation || !config) return response;
      if (config.entityId) {
        this.removeFromCollectionByEntityId(config as Config1);
      } else if (config.fieldValues) {
        removeFragmentByFieldValues(config.fragmentTypeName, config.fieldValues);
      }
      return response;
    });
  }

  onError(error: ApolloError): void {
    this.logger.log(error);
  }

  private removeFromCollectionByEntityId(config: Config1): void {
    const fields: Modifiers = [config.collectionsToBeUpdated].flat().reduce((acc, name) => ({
      ...acc,
      [name]: this.createCacheModifier(config.entityId),
    }), {});
    this.cache.modify({ fields });
  }

  // eslint-disable-next-line class-methods-use-this
  private createCacheModifier(entityId: string): Modifier<any> {
    return function cacheModifier(existingData, { readField }) {
      return (existingData?.nodes || existingData || []).filter((item: any) => entityId !== readField('id', item));
    };
  }
}

export default RemoveEntityFromCollectionLink;
