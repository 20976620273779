import { gql } from '@apollo/client';

const ProjectsOverviewFragment = gql`
  fragment ProjectsOverviewData on ProjectsOverview {
    status
    count
    revenue
    pipelineRevenue
    grossMargin
    pipelineGrossMargin
    grossMarginPct
  }
`;

export default ProjectsOverviewFragment;
