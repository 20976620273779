import { Table } from 'antd';
import styled from 'styled-components';

import {
  TableProps, TablePaginationConfig, ColumnProps, ColumnType, ColumnsType,
} from 'antd/lib/table';
import {
  SorterResult,
  TableCurrentDataSource,
  FilterDropdownProps,
  ColumnFilterItem,
} from 'antd/lib/table/interface';
import theme from 'Shared/constants/theme';

const {
  Column,
  Summary,
} = Table;

const {
  Cell: SummaryCell,
  Row: SummaryRow,
} = Summary;

const CustomSummaryRow = styled(SummaryRow)({
  background: theme.table.summary.background,
  '.ant-table-cell-fix-left, .ant-table-cell-fix-right': {
    background: theme.table.summary.background,
  },
});

export {
  Table, Column as TableColumn,
  Summary, SummaryCell, SummaryRow,
  CustomSummaryRow,
};

export type {
  TableProps,
  TablePaginationConfig,
  ColumnProps,
  ColumnType,
  ColumnsType,
  SorterResult,
  TableCurrentDataSource,
  FilterDropdownProps,
  ColumnFilterItem,
};
