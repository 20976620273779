import { useLocation } from 'react-router-dom';
import { AvailablePortal } from '__generated__/graphql/types';
import useActiveWorkspace from 'Shared/hooks/useActiveWorkspace';
import { useCallback } from 'react';
import getWorkspaceIdentifierFromLocation from 'Shared/utils/routing/getWorkspaceIdentifierFromLocation';
import { CLIENT_PORTAL_ROOT_PATH } from 'ClientPortal/constants';
import { CONSULTANT_PORTAL_ROOT_PATH } from 'ConsultantPortal/constants';

type ReturnVal = {
  isClientPortal(): boolean;
  isConsultantPortal(): boolean;
  isWorkspacePortal(): boolean;
  identifyPortal(): AvailablePortal;
  isOneOf(portals: AvailablePortal[]): boolean;
  hasAccessToPortal(portal: AvailablePortal): boolean;
  appPortal: AvailablePortal;
  appRootPath: string;
};

const appPortalRootPathMap = {
  [AvailablePortal.Client]: CLIENT_PORTAL_ROOT_PATH,
  [AvailablePortal.Consultant]: CONSULTANT_PORTAL_ROOT_PATH,
  [AvailablePortal.Workspace]: '',
};

type ReturnValue = {
  locationRootPath: string;
};

export function getAppRootPath(workspaceIdentifier: string, appPortal: AvailablePortal): string {
  const appPortalRoot = appPortalRootPathMap[appPortal] ? `/${appPortalRootPathMap[appPortal]}` : '';
  return `${workspaceIdentifier}${appPortalRoot}`;
}

export function useAppLocationRootPath(): ReturnValue {
  const { activeWorkspace } = useActiveWorkspace();
  const { appPortal } = useIdentifyAppPortal();

  return {
    locationRootPath: getAppRootPath(activeWorkspace?.identifier || '', appPortal),
  };
}

export const isUserOnClientPortal = (pathname: string, workspaceIdentifier: string): boolean => {
  return pathname.indexOf(getAppRootPath(workspaceIdentifier, AvailablePortal.Client)) !== -1;
};

export const isUserOnConsultantPortal = (pathname: string, workspaceIdentifier: string): boolean => {
  return pathname.indexOf(getAppRootPath(workspaceIdentifier, AvailablePortal.Consultant)) !== -1;
};

export const isUserOnWorkspacePortal = (pathname: string, workspaceIdentifier: string): boolean => {
  return !isUserOnClientPortal(pathname, workspaceIdentifier)
    && !isUserOnConsultantPortal(pathname, workspaceIdentifier);
};

export const identifyCurrentPortal = (pathname: string, workspaceIdentifier: string): AvailablePortal => {
  if (isUserOnClientPortal(pathname, workspaceIdentifier)) return AvailablePortal.Client;
  if (isUserOnConsultantPortal(pathname, workspaceIdentifier)) return AvailablePortal.Consultant;
  return AvailablePortal.Workspace;
};

function useIdentifyAppPortal(): ReturnVal {
  const { pathname } = useLocation();
  const { activeWorkspace } = useActiveWorkspace();
  const workspaceIdentifier = activeWorkspace?.identifier || getWorkspaceIdentifierFromLocation({ pathname });

  const isClientPortal: ReturnVal['isClientPortal'] = () => {
    return isUserOnClientPortal(pathname, workspaceIdentifier);
  };

  const isConsultantPortal: ReturnVal['isConsultantPortal'] = () => {
    return isUserOnConsultantPortal(pathname, workspaceIdentifier);
  };

  const isWorkspacePortal: ReturnVal['isWorkspacePortal'] = () => {
    return !isClientPortal() && !isConsultantPortal();
  };

  const identifyPortal: ReturnVal['identifyPortal'] = () => {
    if (isClientPortal()) return AvailablePortal.Client;
    if (isConsultantPortal()) return AvailablePortal.Consultant;
    return AvailablePortal.Workspace;
  };

  const isOneOf: ReturnVal['isOneOf'] = (portals) => {
    return portals.some((portal) => (
      pathname.indexOf(getAppRootPath(workspaceIdentifier, portal)) !== -1
    ));
  };

  const hasAccessToPortal: ReturnVal['hasAccessToPortal'] = useCallback((portal) => {
    return !!activeWorkspace?.portals.includes(portal);
  }, [activeWorkspace?.portals]);

  return {
    isClientPortal,
    isConsultantPortal,
    isWorkspacePortal,
    identifyPortal,
    isOneOf,
    hasAccessToPortal,
    appPortal: identifyPortal(),
    appRootPath: getAppRootPath(workspaceIdentifier, identifyPortal())
  };
}

export default useIdentifyAppPortal;
