import { gql } from '@apollo/client';

const IndustryFragment = gql`
  fragment IndustryData on ClientIndustry {
    id
    name
  }
`;

export default IndustryFragment;
