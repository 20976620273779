import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';
import WorkspaceContractTemplateFragment from 'WorkspacePortal/api/fragments/WorkspaceContractTemplate/WorkspaceContractTemplateFragment';

const ClientContractProjectFragment = gql`
  fragment ClientContractProjectData on Project {
    id
    name
    locked
    scenario {
      ...ProjectScenarioRequiredData
    }
  }
  ${ProjectScenarioRequiredDataFragment}
`;

const ClientContractFragment = gql`
  fragment ClientContractData on ClientContract {
    id
    name
    type
    status
    docusignStage
    startDate
    endDate
    createdDate
    viewUrl
    downloadUrl
    metaData
    source
    isVoidable
    baseContract {
      id
    }
    project {
      ...ClientContractProjectData
    }
    identifier
    template {
      ...WorkspaceContractTemplateData
    }
  }
  ${ClientContractProjectFragment}
  ${WorkspaceContractTemplateFragment}
`;

export default ClientContractFragment;
