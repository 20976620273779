import { Dropdown } from 'antd';
import { DropDownProps, DropdownButtonProps } from 'antd/lib/dropdown';

const {
  Button,
} = Dropdown;

export {
  Dropdown,
  Button as DropdownButton,
};

export type {
  DropDownProps as DropdownProps,
  DropdownButtonProps,
};
