import { gql } from '@apollo/client';

const PageInfoShortFragment = gql`
  fragment PageInfoShortData on PageInfo {
    page
    totalNodes
    totalPages
  }
`;

export default PageInfoShortFragment;
