import {
  ApolloClient, from, ApolloLink, NormalizedCacheObject, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema } from 'graphql';
import isNaN from 'lodash/isNaN';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';

import { IActiveWorkspaceStorage } from 'Shared/services/activeWorkspace/ActiveWorkspaceStorage';
import { AuthTokenStorage } from 'Shared/services/auth/AuthTokenStorage';
import invalidateCacheConfig from 'Shared/api/cache/cacheInvalidation';
import { createUploadLink } from 'apollo-upload-client';
import InvalidateCacheLink from 'Shared/api/links/InvalidateCacheLink';
import AddEntityToCollectionLink from 'Shared/api/links/AddEntityToCollectionLink';
import ApiNotificationLink from 'Shared/api/links/ApiNotificationLink';
import RemoveEntityFromCollectionLink from 'Shared/api/links/RemoveEntityFromCollectionLink';
import { MutationName, QueryName } from 'Shared/types/api';
import { API_URL } from 'Shared/constants/index';
import { showErrorNotification } from 'Shared/services/notifications';
import { onError } from '@apollo/client/link/error';
import { ILogger } from 'Shared/services/logger';
import { PasswordAuthService } from 'Shared/services/auth/PasswordAuthService';
// import { ICachedDataRepo } from 'api/cache/CachedDataRepository';
import schema from '__generated__/graphql/schema.json';
import { serializeToLocalDateString } from 'Shared/utils/date/localDate';
import { GqlErrorState } from '__generated__/graphql/types';
import AdminPortalActiveWorkspaceStorage from 'AdminPortal/services/AdminPortalActiveWorkspaceStorage';
import isAdminPortalLocation from 'Shared/utils/routing/isAdminPortalLocation';
import AdminPortalAuthService from 'AdminPortal/services/AdminPortalAuthService';

export type Props = {
  client: ApolloClient<NormalizedCacheObject>;
  logger: ILogger;
  activeWorkspaceStorage: IActiveWorkspaceStorage;
  adminPortalWorkspaceStorage: AdminPortalActiveWorkspaceStorage;
  passwordAuthService: PasswordAuthService;
  adminPoralAuthService: AdminPortalAuthService;
  authTokenStorage: AuthTokenStorage;
  cache: InMemoryCache;
  cachedDataRepository: any;
};

// Put links which require client instance here
// For example: from([ setupNewLink(client), setupAnotherLinkWithClient(client), uploadLink, etc. ])
export default function setupApolloClientLinks({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  client,
  logger,
  activeWorkspaceStorage,
  adminPortalWorkspaceStorage,
  passwordAuthService,
  adminPoralAuthService,
  authTokenStorage,
  cache,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cachedDataRepository,
}: Props): ApolloLink {
  // https://www.apollographql.com/docs/react/api/link/apollo-link-context/
  const authLink = setContext((operation, { headers }) => ({
    headers: {
      ...headers,
      authorization: authTokenStorage.token || '',
      workspace: (
        isAdminPortalLocation()
          ? adminPortalWorkspaceStorage.identifier
          : activeWorkspaceStorage.id || ''
      ),
    },
  }));

  // https://github.com/jaydenseric/apollo-upload-client
  // Temporal fix of incorrect type, issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
  const uploadLink = (createUploadLink({
    uri: API_URL,
    formDataAppendFile(formData, fieldName, file: any) {
      formData.append(fieldName, file,
        JSON.stringify({
          filename: file.name,
          filesize: file.size
        }));
    },
  }) as unknown) as ApolloLink;

  // https://www.apollographql.com/docs/react/api/link/apollo-link-error/
  // TODO: we can handle 40x, 500 errors differently in the future
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext();

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        if (!context.meta?.disableErrorNotification) {
          showErrorNotification(
            graphQLError,
            graphQLError?.extensions?.code === GqlErrorState.Permanent ? undefined : {
              duration: 'long'
            });
        }
        logger.log(graphQLError, { operation: operation.operationName });
      });
    }
    if (networkError) {
      showErrorNotification(networkError, { duration: 'long' });
      logger.log(networkError, { operation: operation.operationName });
    }
  });

  const unauthorizedErrorLink = onError(({ graphQLErrors, operation }) => {
    if (!graphQLErrors) return;

    const unauthorizedError = graphQLErrors.find((graphQLError) =>
      graphQLError.extensions?.code === GqlErrorState.Unauthorized);
    if (!unauthorizedError) return;
    logger.log(unauthorizedError, { operation: operation.operationName });
    // const workspaces = cachedDataRepository.getWorkspaces();
    // const anotherAvailableWS = workspaces?.find((ws: any) => ws.id !== activeWorkspaceStorage.id);
    // @todo edgeCase:
    // if users have unauthorized error in every workspace, then they'll stuck in endless loop
    // if (anotherAvailableWS) {
    //   activeWorkspaceStorage.storeId(anotherAvailableWS.id);
    //   passwordAuthService.hardRefresh();
    //   return;
    // }
    if (isAdminPortalLocation()) {
      adminPoralAuthService.hardLogout();
      return;
    }
    passwordAuthService.hardLogout();
  });

  // custom links
  const invalidateCacheLink = new InvalidateCacheLink<MutationName, QueryName>(
    cache,
    invalidateCacheConfig,
  );

  const addEntityToCollectionLink = new AddEntityToCollectionLink(
    cache,
    logger,
  );

  const removeEntityFromCollectionLink = new RemoveEntityFromCollectionLink(
    cache,
    logger,
  );

  const apiNotificationLink = new ApiNotificationLink();

  // https://github.com/eturino/apollo-link-scalars
  const customScalarsLink = withScalars({
    schema: buildClientSchema(schema as any),
    typesMap: {
      'LocalDate': {
        // removes timezone from gql LocalDate Type input
        serialize(value: unknown): string | null | undefined {
          if (isNil(value)) return value;
          const localDate = isDate(value) ? value : new Date(value as string);
          return !isNaN(localDate.getTime())
            ? serializeToLocalDateString(localDate)
            : null;
        },
        // @todo test it when we have LocalDate fields
        parseValue(value: unknown): string | null | unknown {
          if (typeof value === 'string') {
            return serializeToLocalDateString(new Date(value.replace(/T.*/gi, '')));
          }
          return value;
        },
      },
    }
  });

  return from([
    unauthorizedErrorLink,
    errorLink,
    authLink,
    invalidateCacheLink,
    addEntityToCollectionLink,
    removeEntityFromCollectionLink,
    apiNotificationLink,
    customScalarsLink,
    uploadLink,
  ]);
}
