import { gql } from '@apollo/client';

const ProjectRoleOverviewFragment = gql`
  fragment ProjectRoleOverviewData on ProjectRoleOverview {
    id
    name
    avatarUrl
    role
    days
    consultantCharge
    #consultantCost
    #grossMargin
    grossMarginPct
  }
`;

const ProjectRolesOverviewFragment = gql`
  fragment ProjectRolesOverviewData on ProjectRolesOverview {
    id
    totalConsultantCharge
    totalConsultantCost
    grossMarginPct
    totalGrossMargin
    consultants {
      ...ProjectRoleOverviewData
    }
  }
  ${ProjectRoleOverviewFragment}
`;

export default ProjectRolesOverviewFragment;
