import {
  InvalidateCacheConfig,
} from 'Shared/api/links/InvalidateCacheLink';
import { QueryName, MutationName } from 'Shared/types/api';

const companyHolidaysInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  updateWorkspacePublicHolidays: {
    invalidateOperations: [
      { operationName: 'utilisation', variables: () => ({ }) }
    ]
  }
};

export default companyHolidaysInvalidationConfig;
