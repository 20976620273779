import { gql } from '@apollo/client';

const InitialDAContractDataFragment = gql`
  fragment InitialDAContractDataFields on InitialDAContractData {
    consultantSignerEmail
    startDate
    endDate
    objectives
    keyResults
    projectName
    currencySymbol
    consultantMaxRate
    serviceAgreementRef
    deliverables {
      id
      name
      deadline
      description
      assumptions
    }
  }
`;

export default InitialDAContractDataFragment;
