import { gql } from '@apollo/client';

const ExpenseCategoryFragment = gql`
  fragment ExpenseCategoryData on ExpenseCategory {
    id
    name
    sortOrder
  }
`;

export default ExpenseCategoryFragment;
