import { gql } from '@apollo/client';
import InvoiceConsultantFragment from '../Consultant/InvoiceConsultantFragment';

const ProjectInvoiceRoleFragment = gql`
  fragment ProjectInvoiceRoleData on ProjectRole {
      id
      role
      unitType
      consultant {
        ...InvoiceConsultantData
      }
    }
    ${InvoiceConsultantFragment}
`;

export default ProjectInvoiceRoleFragment;
