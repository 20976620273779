import { ADMIN_PORTAL_WORKSPACE_ID_STORAGE_NAME } from 'AdminPortal/constants/index';
import { IAppStorage } from 'Shared/services/storage';


export default class AdminPortalActiveWorkspaceStorage {
  constructor(private readonly storage: IAppStorage) {}

  get identifier(): string | undefined {
    return this.storage.getItem(ADMIN_PORTAL_WORKSPACE_ID_STORAGE_NAME) || undefined;
  }

  store(identifier: string): void {
    this.storage.storeItem(ADMIN_PORTAL_WORKSPACE_ID_STORAGE_NAME, identifier);
  }

  clear(): void {
    this.storage.removeItem(ADMIN_PORTAL_WORKSPACE_ID_STORAGE_NAME);
  }
}
