import { gql } from '@apollo/client';
import UserFragment from 'Shared/api/fragments/User/UserFragment';

const CommonProjectFieldsFragment = gql`
  fragment CommonProjectFields on Project {
    id
    name
    status
    stage
    isOpenForBooking
    #startDate
    #endDate
    #revenue
    #grossMargin
    #grossMarginPct
    winProbability
    visibleToClient
    assignedUser {
      ...UserData
    }
    engagementSupport {
      ...UserData
    }
    currency
  }
  ${UserFragment}
`;

export default CommonProjectFieldsFragment;
