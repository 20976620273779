import { gql } from '@apollo/client';

const CurrencyRateFragment = gql`
  fragment CurrencyRateData on CurrencyRate {
    id
    currency
    rate
    updatedAt
  }
`;

export default CurrencyRateFragment;
