import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from './ProjectScenarioRequiredDataFragment';

const ProjectScenarioFragment = gql`
  fragment ProjectScenarioData on ProjectScenario {
    ...ProjectScenarioRequiredData
    name
    isActive
    isContracted
    createdAt
    description
    slugs
    budget
    revenue
    projectType
    startDate
    endDate
  }
  ${ProjectScenarioRequiredDataFragment}
`;

export default ProjectScenarioFragment;
