import { gql } from '@apollo/client';

const UserProfileFragment = gql`
  fragment UserProfileData on UserProfile {
    id
    firstName
    lastName
    location
    phone
    avatarUrl
    notificationEmail
    position
  }
`;

export default UserProfileFragment;
