import { gql } from '@apollo/client';

const ConsultantToBeOnboardedFragment = gql`
  fragment ConsultantToBeOnboardedData on WorkspaceConsultant {
    id
    name
    email
    avatarUrl
  }
`;

export default ConsultantToBeOnboardedFragment;
