import { gql } from '@apollo/client';
import AuthPayloadFragment from 'Shared/api/fragments/AuthPayload/AuthPayloadFragment';

export const LOGIN_WITH_PASSWORD = gql`
  mutation loginWithPassword($email: String!, $password: String!) {
    loginWithPassword(email: $email, password: $password) {
      ...AuthPayloadData
    }
  }
  ${AuthPayloadFragment}
`;

export const RESET_PASSWORD = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const CONFIRM_RESET_PASSWORD = gql`
  mutation confirmPasswordReset($token: String!, $password: String!) {
    confirmPasswordReset(token: $token, password: $password) {
      ...AuthPayloadData
    }
  }
  ${AuthPayloadFragment}
`;

export const LOGIN_WITH_LINKEDIN_CODE = gql`
  mutation loginWithLinkedInCode($identifier: String!, $code: String!) {
    loginWithLinkedInCode(identifier: $identifier, code: $code) {
      ...AuthPayloadData
    }
  }
  ${AuthPayloadFragment}
`;
