import { gql } from '@apollo/client';

const InvoiceConsultantFragment = gql`
  fragment InvoiceConsultantData on WorkspaceConsultant {
    name
    avatarUrl
  }
`;

export default InvoiceConsultantFragment;
