import { gql } from '@apollo/client';

const DocumentTypeFragment = gql`
  fragment DocumentTypeData on DocumentType {
    id
    name
    externalKey
    scope
  }
`;

export default DocumentTypeFragment;
