import { useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import CacheRepository, { ICachedDataRepo } from 'Shared/api/cache/CachedDataRepository';

function useGetFromCache(): ICachedDataRepo {
  const client = useApolloClient();
  const cacheFinder = useMemo(() => new CacheRepository(client.cache), [client.cache]);
  return cacheFinder;
}

export default useGetFromCache;
