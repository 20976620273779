import { gql } from '@apollo/client';

const BudgetOverviewFragment = gql`
  fragment BudgetOverviewData on BudgetOverview {
    id
    consultantCharges
    expenseCharges
    totalCharges
    #totalCost
    budget
    totalGrossMargin
    grossMarginPct
  }
`;

export default BudgetOverviewFragment;
