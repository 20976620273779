import { gql } from '@apollo/client';
import ConsultantBaseFragment from 'Shared/api/fragments/Consultant/ConsultantBaseFragment';

const ProjectDeliverableFragment = gql`
  fragment ProjectDeliverableData on ProjectDeliverable {
    id
    consultant {
      ...ConsultantBaseData
    }
    stage
    name
    deadline
    description
    assumptions
  }
  ${ConsultantBaseFragment}
`;

export default ProjectDeliverableFragment;
