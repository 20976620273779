import {
  InvalidateCacheConfig,
  VariablesGetterProps,
  VariablesGetterReturnType,
} from 'Shared/api/links/InvalidateCacheLink';
import {
  ContractType,
  CreateClientContractMutationVariables,
  CreateConsultantContractMutationVariables,
  VerifyConsultantBusinessInfoMutationVariables,
  CreateProjectDeliverableMutationVariables,
} from '__generated__/graphql/types';
import { QueryName, MutationName } from 'Shared/types/api';
import { logger } from 'globalModules';
import projectExpensesInvalidationConfig from './invalidationConfigs/projectExpensesInvalivalidationConfig';
import workspaceUsersInvalidationConfig from './invalidationConfigs/workspaceUsersInvalidationConfig';
import consultantInvalidationConfig from './invalidationConfigs/consultantInvalidationConfig';
import projectInvalidationConfig from './invalidationConfigs/projectInvalidationConfig';
import projectRolesInvalidationConfig from 'Shared/api/cache/invalidationConfigs/projectRolesInvalidationConfig';
import invoicesInvalidationConfig from 'Shared/api/cache/invalidationConfigs/invoicesInvalidationConfig';
import companyHolidaysInvalidationConfig from 'Shared/api/cache/invalidationConfigs/companyHolidaysInvalidationConfig';

type VariablesProps<TMutationVariables, TContext = any,
> = VariablesGetterProps<TMutationVariables, TContext>;

// @todo extract into several config files
const invalidateCacheConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  createClientContact: {
    invalidateOperations: [
      {
        operationName: 'clientContacts',
        variables: ({ variables }) => ({
          clientId: variables.clientId,
        }),
      },
    ],
  },
  createConsultantContract: {
    invalidateOperations: [
      {
        operationName: 'consultantContracts',
        variables: ({
          variables,
        }: VariablesProps<CreateConsultantContractMutationVariables>):
        VariablesGetterReturnType => {
          if (variables.contractInfo.type === ContractType.Da) return undefined;
          return {
            consultantId: variables.consultantId,
          };
        },
      },
      {
        operationName: 'projectConsultantContracts',
        variables: ({
          variables,
        }: VariablesProps<CreateConsultantContractMutationVariables>):
        VariablesGetterReturnType => {
          if (variables.contractInfo.type !== ContractType.Da) return undefined;
          const { projectId } = variables.contractInfo;
          if (!projectId) {
            logger.log('Cannot refetch projectConsultantContracts [mutation createConsultantContract]');
            return undefined;
          }
          return {
            projectId,
          };
        },
      },
    ],
  },
  createClientContract: {
    invalidateOperations: [
      {
        operationName: 'clientContracts',
        variables: ({
          variables,
        }: VariablesProps<CreateClientContractMutationVariables>):
        VariablesGetterReturnType => {
          const { clientId } = variables;
          return { clientId };
        },
      },
      {
        operationName: 'projectClientContracts',
        variables: ({ variables }: VariablesProps<CreateClientContractMutationVariables>):
        VariablesGetterReturnType => {
          const { projectId } = variables.contractInfo;
          if (!projectId) return undefined;
          return { projectId };
        },
      },
    ],
  },
  verifyConsultantBusinessInfo: {
    invalidateOperations: [
      {
        operationName: 'consultantBusinessInfo',
        variables: ({
          variables,
        }: VariablesProps<VerifyConsultantBusinessInfoMutationVariables>) => ({
          consultantId: variables.consultantId,
        }),
      },
    ],
  },
  updateWorkspaceSettings: {
    invalidateOperations: [{ operationName: 'me' }],
  },
  removeWorkspaceClient: {
    invalidateOperations: [
      { operationName: 'timesheetReport', variables: () => ({}) },
    ],
  },
  createProjectDeliverable: {
    invalidateOperations: [
      {
        operationName: 'projectDeliverables',
        variables: ({ variables }: VariablesProps<CreateProjectDeliverableMutationVariables>):
        VariablesGetterReturnType => {
          const { projectId, scenarioSlug } = variables;
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
    ],
  },
  updateWorkspaceContractTemplateStatus: {
    invalidateOperations: [
      { operationName: 'workspaceContractTemplates', variables: () => ({}) },
    ],
  },
  updateWorkspaceContractTemplate: {
    invalidateOperations: [
      { operationName: 'workspaceContractTemplates', variables: () => ({}) },
    ],
  },
  ...projectInvalidationConfig,
  ...projectRolesInvalidationConfig,
  ...projectExpensesInvalidationConfig,
  ...workspaceUsersInvalidationConfig,
  ...consultantInvalidationConfig,
  ...invoicesInvalidationConfig,
  ...companyHolidaysInvalidationConfig,
};

export default invalidateCacheConfig;
