import { gql } from '@apollo/client';
import UserFragment from 'Shared/api/fragments/User/UserFragment';

const NoteBaseFragment = gql`
  fragment NoteBaseData on NoteBase {
    id
    content
    updatedAt
    createdByUser {
      ...UserData
    }
    isPinned
  }
  ${UserFragment}
`;

export default NoteBaseFragment;
