import { gql } from '@apollo/client';

const ConsultantLinkFragment = gql`
  fragment ConsultantLinkData on ConsultantLink {
    id
    linkUrl
    description
    createdAt
  }
`;

export default ConsultantLinkFragment;
