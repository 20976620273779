import { gql } from '@apollo/client';

const ClientContactFragment = gql`
  fragment ClientContactData on ClientContact {
    id
    name
    role
    email
    phone
    isFavorite
  }
`;

export default ClientContactFragment;
