import {
  InvalidateCacheConfig,
  VariablesGetterProps,
  VariablesGetterReturnType,
} from 'Shared/api/links/InvalidateCacheLink';
import {
  CreateProjectRoleMutationVariables,
  ProjectRoleWorkPlanQueryVariables,
  RemoveProjectRoleMutationVariables,
  Scalars,
  UpdateProjectRoleMutationVariables,
  UpdateProjectRoleWorkPlanMutationVariables,
  WorkspaceProjectQueryVariables,
} from '__generated__/graphql/types';
import { QueryName, MutationName } from 'Shared/types/api';
import { logger } from 'globalModules';

type VariablesProps<TMutationVariables, TContext = any,
> = VariablesGetterProps<TMutationVariables, TContext>;

export type UpdateProjectRoleCostsContext = {
  meta: {
    projectId: Scalars['ID'];
    clientId: Scalars['ID'];
    scenarioSlug: string;
  }
};
export type UpdateProjectRoleContext = {
  meta: {
    projectId: Scalars['ID'];
    scenarioSlug?: string;
  }
};

const projectRolesInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  createProjectRole: {
    extend: 'updateProjectRole',
    invalidateOperations: [
      {
        operationName: 'projectRoles',
        variables: ({ variables }: VariablesProps<CreateProjectRoleMutationVariables>) => ({
          projectId: variables.projectId,
          scenarioSlug: variables.scenarioSlug,
        }),
      },
    ],
  },
  updateProjectRole: {
    invalidateOperations: [
      {
        operationName: 'workspaceProject',
        variables: ({
          variables,
          context,
        }: VariablesProps<UpdateProjectRoleMutationVariables | CreateProjectRoleMutationVariables,
        UpdateProjectRoleContext>):
        VariablesGetterReturnType => {
          const projectId = (
            variables as CreateProjectRoleMutationVariables).projectId || context.meta.projectId;
          const queryVars: WorkspaceProjectQueryVariables = {
            projectId,
          };
          return queryVars;
        },
      },
      { operationName: 'projectsOverview' },
      {
        // projectsOverview query from client page
        operationName: 'projectsOverview',
        variables: () => ({}),
      },
      {
        operationName: 'consultantsAssignedToProject',
        variables: ({
          variables,
          context,
        }: VariablesProps<UpdateProjectRoleMutationVariables | CreateProjectRoleMutationVariables,
        UpdateProjectRoleContext>):
        VariablesGetterReturnType => {
          const projectId = (
            variables as CreateProjectRoleMutationVariables).projectId || context.meta.projectId;
          const scenarioSlug = (variables as CreateProjectRoleMutationVariables).scenarioSlug
            || context.meta.scenarioSlug;
          if (!projectId) {
            logger.log('Cannot invalidate consultantsAssignedToProject [mutation updateProjectRole]');
            return undefined;
          }
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      {
        operationName: 'consultantProjectRoles',
        variables: ({
          variables,
          context,
        }: VariablesProps<CreateProjectRoleMutationVariables>): VariablesGetterReturnType => {
          const consultantId = variables?.projectRoleInfo?.consultantId
            || context?.meta?.consultantId;
          if (!consultantId) {
            // Don't invalidate if consultant is not defined for a project role;
            return undefined;
          }
          return {
            consultantId,
          };
        },
      },
      {
        operationName: 'projectTimesheetPlanner',
        variables: ({
          variables,
          context,
        }: VariablesProps<CreateProjectRoleMutationVariables>): VariablesGetterReturnType => {
          const projectId = variables.projectId || context.meta.projectId;
          return {
            projectId,
          };
        },
      },
      { operationName: 'timesheetReport', variables: () => ({}) },
      { operationName: 'utilisation', variables: () => ({}) },
      {
        operationName: 'budgetOverview',
        variables: ({ variables, context }: VariablesProps<
        UpdateProjectRoleMutationVariables
        | CreateProjectRoleMutationVariables,
        UpdateProjectRoleContext>):
        VariablesGetterReturnType => {
          const projectId = (variables as CreateProjectRoleMutationVariables).projectId
            || context.meta.projectId;
          const scenarioSlug = (variables as CreateProjectRoleMutationVariables).scenarioSlug
            || context.meta.scenarioSlug;
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      {
        operationName: 'projectRolesOverview',
        variables: ({ variables, context }: VariablesProps<
        UpdateProjectRoleMutationVariables
        | CreateProjectRoleMutationVariables,
        UpdateProjectRoleContext>):
        VariablesGetterReturnType => {
          const projectId = (variables as CreateProjectRoleMutationVariables).projectId
            || context.meta.projectId;
          const scenarioSlug = (variables as CreateProjectRoleMutationVariables).scenarioSlug
            || context.meta.scenarioSlug;
          return {
            projectId,
            scenarioSlug,
          };
        },
      },
      {
        operationName: 'consultantProjectsOverview',
        variables: () => ({}),
      }
    ],
  },
  removeProjectRole: {
    extend: 'updateProjectRole',
    invalidateOperations: [
      {
        operationName: 'workspaceProject',
        variables: ({
          context,
        }: VariablesProps<RemoveProjectRoleMutationVariables>):
        VariablesGetterReturnType => {
          const { projectId } = context.meta;
          if (!projectId) {
            logger.log('Cannot refetch workspaceProject [mutation removeProjectRole]');
            return undefined;
          }
          return {
            projectId,
          };
        },
      },
    ],
  },
  updateProjectRoleCosts: {
    invalidateOperations: [
      { operationName: 'projectsOverview' },
      {
        operationName: 'projectsOverview',
        variables: ({
          context,
        }: VariablesProps<UpdateProjectRoleMutationVariables, UpdateProjectRoleCostsContext>) => ({
          clientId: context.meta.clientId,
        }),
      },
      {
        operationName: 'workspaceProject',
        variables: ({
          context,
        }: VariablesProps<UpdateProjectRoleMutationVariables, UpdateProjectRoleCostsContext>):
        VariablesGetterReturnType => {
          const queryVars: WorkspaceProjectQueryVariables = {
            projectId: context.meta.projectId,
          };
          return queryVars;
        },
      },
    ],
  },
  updateProjectRoleWorkPlan: {
    invalidateOperations: [
      {
        operationName: 'projectRoleWorkPlan',
        variables: ({
          variables
        }: VariablesProps<UpdateProjectRoleWorkPlanMutationVariables>) => {
          const queryVariables: ProjectRoleWorkPlanQueryVariables = {
            roleId: variables.roleId,
          };
          return queryVariables;
        },
      },
    ]
  }
};

export default projectRolesInvalidationConfig;
