import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';

const ProjectScenarioFullFragment = gql`
  fragment ProjectScenarioFullData on ProjectScenario {
    ...ProjectScenarioRequiredData
    name
    slugs
    description
    startDate
    endDate
    objectives
    keyResults
    revenue
    grossMargin
    grossMarginPct
    isActive
    isContracted
    createdAt
    budget
    projectType
    budgetedRevenue
    budgetedCost
    budgetedGrossMargin
    forecastedRevenue
    forecastedCost
    forecastedGrossMargin
    discount
    totalInvoiced
    totalUninvoiced
    lastInvoiced
  }
  ${ProjectScenarioRequiredDataFragment}
`;

export default ProjectScenarioFullFragment;
