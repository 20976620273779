import {
  Row,
  Col as AntdCol,
  ColProps,
  RowProps,
} from 'antd';
import styled, { CSSObject } from 'styled-components';
import { TextAlign } from './types';

interface ColCustomProps {
  textalign?: TextAlign;
}

const Col = styled(AntdCol)((props: ColCustomProps) => ({
  textAlign: props.textalign,
}));

type ColumnSizing = 'fit' | 'fill';
function getColumnSizing(sizing: ColumnSizing): string {
  switch (sizing) {
    case 'fill':
      return 'auto-fill';
    case 'fit':
      return 'auto-fit';
    default:
      return 'auto';
  }
}

const AutoFitGrid = styled.div(({
  maxColumnWidth,
  gap,
  columnSizing
}: {
  maxColumnWidth: number;
  gap: number;
  columnSizing: 'fit' | 'fill'
}) => ({
  display: 'grid',
  rowGap: gap,
  columnGap: gap,
  gridTemplateColumns: `repeat(${getColumnSizing(columnSizing)}, minmax(min(100%/2, max(${maxColumnWidth}px, 100%/10)), 1fr))`,
}));

const FlexGrid = styled.div((props: { gap: number }) => ({
  display: 'flex',
  gap: props.gap,
  flexWrap: 'wrap',
  alignItems: 'start',
  justifyContent: 'flex-start',
}) as CSSObject);

export type { ColProps, RowProps };
export { Row, Col, AutoFitGrid, FlexGrid };
