import { gql } from '@apollo/client';
import AdminPortalAuthPayloadFragment from '../fragments/Auth/AdminPortalAuth';

export const ADMIN_PORTAL_LOGIN = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      ...AdminPortalAuthPayloadData
    }
  }
  ${AdminPortalAuthPayloadFragment}
`;
