/* eslint-disable */

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ConsultantBase": [
      "ClientPortalConsultant",
      "ConsultantPreview",
      "WorkspaceConsultant"
    ],
    "Contract": [
      "ClientContract",
      "ConsultantContract"
    ],
    "DuplicatesEntity": [
      "WorkspaceClient"
    ],
    "FinDashboardGroupValue": [
      "Period",
      "Project",
      "WorkspaceClient"
    ]
  }
};
      export default result;
    