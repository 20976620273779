import { REGISTRATION_PAGE_PATH } from 'WorkspacePortal/constants/index';
import { CONSULTANT_PORTAL_ROOT_PATH } from 'ConsultantPortal/constants';
import { AvailablePortal } from '__generated__/graphql/types';

export const CONSULTANT_REGISTRATION_PAGE_REACT_ROUTE = `/${CONSULTANT_PORTAL_ROOT_PATH}/${REGISTRATION_PAGE_PATH}/:workspaceIdentifier`;
export const WORKSPACE_REGISTRATION_PAGE_REACT_ROUTE = `/${REGISTRATION_PAGE_PATH}`;

export function getWorkspcePortalRegistrationPagePath(): string {
  return REGISTRATION_PAGE_PATH;
}

export function getConsultantPortalRegistrationPagePath(workspaceIdentifier: string): string {
  return `${CONSULTANT_PORTAL_ROOT_PATH}/${REGISTRATION_PAGE_PATH}/${workspaceIdentifier}`;
}

export function getRegistrationPagePath(
  portal: Exclude<AvailablePortal, AvailablePortal.Client>,
  workspace: string
): string {
  switch (portal) {
    case AvailablePortal.Workspace:
      return getWorkspcePortalRegistrationPagePath();
    case AvailablePortal.Consultant:
      return getConsultantPortalRegistrationPagePath(workspace);
    // we don't have registration page for client portal. users can be only invited
    // case AvailablePortal.Client:
    //   return getClientPortalLoginPagePath();
    default:
      throw new Error(`Registration path for ${portal} is not defined!`);
  }
}
