import { gql } from '@apollo/client';

const WorkspacePublicHolidayFragment = gql`
  fragment WorkspacePublicHolidayData on WorkspacePublicHoliday {
    date
    label
    description
  }
`;

export default WorkspacePublicHolidayFragment;
