import {
  InvalidateCacheConfig, VariablesGetterProps, VariablesGetterReturnType,
} from 'Shared/api/links/InvalidateCacheLink';
import { QueryName, MutationName } from 'Shared/types/api';
import { UpdateUserAccessLevelMutationVariables, WorkspaceAccessLevel, WorkspaceUsersAndInvitesFilterType } from '__generated__/graphql/types';

const workspaceUsersInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  updateUserAccessLevel: {
    invalidateOperations: [
      {
        operationName: 'workspaceMemberUsersAndInvites',
        variables({
          variables,
        }: VariablesGetterProps<
        UpdateUserAccessLevelMutationVariables>): VariablesGetterReturnType {
          if (variables.accessLevel === WorkspaceAccessLevel.Owner) {
            // invalidate cache only when access level is being changed on Owner
            return {
              filter: {
                type: WorkspaceUsersAndInvitesFilterType.Members,
              },
            };
          }
          return undefined;
        },
      },
    ],
  },
};

export default workspaceUsersInvalidationConfig;
