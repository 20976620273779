import { Collapse } from 'antd';
import { CollapsePanelProps, CollapseProps } from 'antd/lib/collapse';

const {
  Panel,
} = Collapse;

export {
  Collapse,
  Panel as CollapsePanel,
};

export type {
  CollapsePanelProps,
  CollapseProps,
};
