import { gql } from '@apollo/client';
import CurrencyRateFragment from 'Shared/api/fragments/Currency/CurrencyRateFragment';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';

const ProjectAutocompleteFragment = gql`
  fragment ProjectAutocompleteData on Project {
    id
    name
    currency
    stage
    currencyRates {
      ...CurrencyRateData
    }
    client {
      id
      name
      clientBillingAddress
    }
    scenario {
      ...ProjectScenarioRequiredData
      startDate
      endDate
      discount
    }
  }
  ${ProjectScenarioRequiredDataFragment}
  ${CurrencyRateFragment}
`;

export default ProjectAutocompleteFragment;
