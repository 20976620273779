import {
  ApolloClient, InMemoryCache, NormalizedCacheObject,
} from '@apollo/client';
// import cache from 'api/cache/cache';
import setupApolloClientLinks, { Props as SetupApolloClientLinksProps } from 'Shared/api/links/setupClientLinks';
import { IS_CONNECTED_TO_APOLLO_DEV_TOOLS } from 'Shared/constants/index';
import isAdminPortalLocation from 'Shared/utils/routing/isAdminPortalLocation';

let client: ApolloClient<NormalizedCacheObject>;

type InitClientProps = {
  cache: InMemoryCache;
};

function initClient({
  cache,
}: InitClientProps): typeof client {
  if (client) return client;

  client = new ApolloClient({
    cache,
    connectToDevTools: IS_CONNECTED_TO_APOLLO_DEV_TOOLS,
    defaultOptions: {
      query: {
        fetchPolicy: isAdminPortalLocation() ? 'network-only' : undefined
      }
    }
  });

  return client;
}

// const client = new ApolloClient({
//   cache,
//   connectToDevTools: IS_CONNECTED_TO_APOLLO_DEV_TOOLS,
// });

export function setupClientLinks(props: SetupApolloClientLinksProps): typeof client {
  client.setLink(setupApolloClientLinks(props));
  return client;
}

export default initClient;
