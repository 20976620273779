import { gql } from '@apollo/client';

const DatePeriodFragment = gql`
  fragment DatePeriodData on DatePeriod {
    startDate
    endDate
    label
  }
`;

const ConsultantAvailabilityFragment = gql`
  fragment ConsultantAvailabilityData on ConsultantAvailability {
    id
    isAvailable
	  capacity
	  daysOff {
      ...DatePeriodData
    }
    availableFrom
	  updatedAt
	  requestedAt
  }
  ${DatePeriodFragment}
`;

export default ConsultantAvailabilityFragment;
