import { gql } from '@apollo/client';
import { ExpenseCategoryFragment } from 'WorkspacePortal/api/fragments';

const ProjectExpenseFragment = gql`
  fragment ProjectExpenseData on ProjectExpense {
    id
    category {
      ...ExpenseCategoryData
    }
    description
    date
    units
    unitCost
    clientUnitCharge
    cost
    clientCharge
    grossMarginPct
  }
  ${ExpenseCategoryFragment}
`;

export default ProjectExpenseFragment;