import React, { FC } from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar';
import { UserOutlined } from '@ant-design/icons';
import theme from 'Shared/constants/theme';
import { CSSObject } from 'styled-components';
import get from 'lodash/get';
import { Size } from './types';
import { cssPixelToNumber } from 'Shared/utils';

export type AvatarColor = keyof typeof theme.avatar.colors;
export const AvatarDefaultIcon = UserOutlined;
export type AvatarSize = Size;

interface AvatarProps extends Omit<AntdAvatarProps, 'size'> {
  size: AvatarSize | number;
  color?: AvatarColor;
}

const getAvatarSize = (size: AvatarProps['size']): AntdAvatarProps['size'] => {
  if (!size) return undefined;
  if (typeof size === 'object' || typeof size === 'number') return size;
  return cssPixelToNumber(get(theme.size, size, size));
};

const getAvatarColorStyles = (color?: AvatarColor): CSSObject | undefined => {
  if (!color) return undefined;
  const colorMap = theme.avatar.colors[color];
  return {
    color: colorMap.font,
    backgroundColor: colorMap.bg,
  };
};

const Avatar: FC<AvatarProps> = ({
  size,
  color,
  style,
  ...rest
}) => (
  <AntdAvatar
    size={getAvatarSize(size)}
    icon={<AvatarDefaultIcon />}
    style={{
      ...getAvatarColorStyles(color),
      ...style,
    }}
    {...rest}
  />
);

const {
  Group: AvatarGroup,
} = AntdAvatar;

export { Avatar, AvatarGroup };

export type { AvatarProps };
