import React, { FC, lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import PageLoader from 'Shared/components/Common/Page/PageLoader';
import { PUBLIC_JOB_PAGE_ROUTE, PUBLIC_JOBS_PAGE_ROUTE } from 'Shared/constants';
import { CLIENT_LOGIN_PAGE_REACT_ROUTE, CONSULTANT_LOGIN_PAGE_REACT_ROUTE, WORKSPACE_LOGIN_PAGE_REACT_ROUTE, ADMIN_LOGIN_PAGE_REACT_ROUTE } from 'Shared/utils/routing/getLoginPagePath';
import { CONSULTANT_REGISTRATION_PAGE_REACT_ROUTE, WORKSPACE_REGISTRATION_PAGE_REACT_ROUTE } from 'Shared/utils/routing/getRegistrationPagePath';
import { ADMIN_PORTAL_ROOT_PATH } from 'AdminPortal/constants';

const Login = lazy(() => import('WorkspacePortal/pages/Login/Login'));
const Registration = lazy(() => import('WorkspacePortal/pages/Registration/Registration'));
const Onboarding = lazy(() => import('WorkspacePortal/pages/Onboarding'));
const NotificationsRouter = lazy(() => import('Shared/routers/NotificationsRouter'));
const ForgotPassword = lazy(() => import('Shared/pages/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('Shared/pages/ResetPassword/ResetPassword'));
const AcceptWorkspaceInvitation = lazy(() => import('Shared/pages/AcceptInvitation/AcceptInvitation'));
const ConfirmOrChangeWeeklyEngagement = lazy(() => import('WorkspacePortal/pages/WeeklyEngagement/ConfirmOrChangeWeeklyEngagement'));
const ApproveWeeklyEngagement = lazy(() => import('WorkspacePortal/pages/WeeklyEngagement/ApproveWeeklyEngagement'));
const PrivatePagesRouter = lazy(() => import('Shared/routers/PrivatePagesRouter'));
// client portal public pages
const ClientPortalLogin = lazy(() => import('ClientPortal/pages/Login'));

// consultant portal public pages
const ConsultantLogin = lazy(() => import('ConsultantPortal/pages/ConsultantLogin'));
const ConsultantRegistration = lazy(() => import('ConsultantPortal/pages/ConsultantRegistration/ConsultantRegistration'));

// workspace public pages (do not require authirization)
const PublicJobs = lazy(() => import('WorkspacePortal/pages/Jobs/PublicJobs'));
const PublicJob = lazy(() => import('WorkspacePortal/pages/Jobs/PublicJob'));

// admin portal public pages
const AdminPortalLogin = lazy(() => import('AdminPortal/pages/Login/Login'));
const AdminPortalRouter = lazy(() => import('AdminPortal/router/AdminPortalRouter'));

const PublicPagesRouter: FC<unknown> = () => (
  <Suspense fallback={<PageLoader />}>
    <Switch>
      {/* consultant portal */}
      <Route path={CONSULTANT_LOGIN_PAGE_REACT_ROUTE}>
        <ConsultantLogin />
      </Route>
      <Route path={CONSULTANT_REGISTRATION_PAGE_REACT_ROUTE}>
        <ConsultantRegistration />
      </Route>

      <Route path={WORKSPACE_LOGIN_PAGE_REACT_ROUTE}>
        <Login />
      </Route>
      <Route path={WORKSPACE_REGISTRATION_PAGE_REACT_ROUTE}>
        <Registration />
      </Route>
      <Route path="/onboarding/step/:step">
        <Onboarding />
      </Route>
      <Route path="/notifications/:notificationType">
        <NotificationsRouter />
      </Route>
      <Route path="/forgot-password/:portal/:workspaceIdentifier?">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/accept-workspace-invitation/:token">
        <AcceptWorkspaceInvitation />
      </Route>
      <Route path="/weekly-engagement/:token/:action">
        <ConfirmOrChangeWeeklyEngagement />
      </Route>
      <Route path="/approve-weekly-engagement/:token">
        <ApproveWeeklyEngagement />
      </Route>
      <Route path={CLIENT_LOGIN_PAGE_REACT_ROUTE}>
        <ClientPortalLogin />
      </Route>

      {/* admin portal */}
      <Route path={ADMIN_LOGIN_PAGE_REACT_ROUTE}>
        <AdminPortalLogin />
      </Route>
      <Route path={`/${ADMIN_PORTAL_ROOT_PATH}`}>
        <AdminPortalRouter />
      </Route>

      {/* workspace public pages [not required authorization] */}
      <Route path={PUBLIC_JOB_PAGE_ROUTE}>
        <PublicJob />
      </Route>
      <Route path={PUBLIC_JOBS_PAGE_ROUTE}>
        <PublicJobs />
      </Route>

      {/* workspace authorized pages */}
      <Route path="*">
        <PrivatePagesRouter />
      </Route>
    </Switch>
  </Suspense>
);

export default PublicPagesRouter;
