import { gql } from '@apollo/client';

const ExpenseCategoryOverviewFragment = gql`
  fragment ExpenseCategoryOverviewData on CategoryOverview {
    id
    name
    totalCost
    totalClientCharge
    totalGrossMargin
    grossMarginPct
  }
`;

export default ExpenseCategoryOverviewFragment;
