import startCase from 'lodash/startCase';
import omitBy from 'lodash/omitBy';
import mergeWith from 'lodash/mergeWith';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import compact from 'lodash/compact';
import first from 'lodash/first';

import { NonEmptyField, UnknownObject } from 'Shared/types/utils';
import {
  REQUEST_NEW_CONSULTANT_SURVEY_ID,
  REQUEST_EXISTING_CONSULTANT_SURVEY_ID,
} from 'WorkspacePortal/constants/index';
import {
  APP_ENVIRONMENT,
  API_URL,
  AUTH_TOKEN_NAME,
  SENTRY_DSN,
  IS_PRODUCTION,
  DECIMAL_POINT,
} from 'Shared/constants';
import { logger } from 'globalModules';

export type ArrayItem<TValue> = {
  value: TValue;
  text: string;
};

export function convertEnumToArray<TValue>(input: Record<string, TValue>): ArrayItem<TValue>[] {
  return Object.values(input).map((value) => ({
    value,
    text: startCase(String(value)),
  }));
}

const regex = /\s?,\s?/g; // remove whitespaces between items, i.e 'a, b , c ,d' => 'a,b,c,d'
export function convertStringToArray(str: string): string[] {
  return (str || '').replace(regex, ',').split(',');
}

export function omitObjectEmptyValues<T extends Record<string, unknown>>(
  data: T,
): NonEmptyField<T> {
  return omitBy<T>(data, (value) => isNil(value) || value === '') as NonEmptyField<T>;
}

// Merges two objects, replacing defaultData value with source value only if source is not empty
export function mergeWithDefaultData<TObject = UnknownObject, TSource = UnknownObject>(
  defaultData: TObject,
  source: TSource,
): TObject & TSource {
  return mergeWith({}, defaultData, source, (defaultDataValue, sourceValue) => {
    if (isNil(sourceValue)) return defaultDataValue;
    return sourceValue;
  });
}

export function ensureAllRequiredEnvVariablesDefined(): void {
  [
    // [variable key, variable value, is variable required only on production]
    ['APP_ENVIRONMENT', APP_ENVIRONMENT],
    ['API_URL', API_URL],
    ['AUTH_TOKEN_NAME', AUTH_TOKEN_NAME],
    ['SENTRY_DSN', SENTRY_DSN, true],
    ['REACT_APP_REQUEST_NEW_CONSULTANT_SURVEY_ID', REQUEST_NEW_CONSULTANT_SURVEY_ID, true],
    ['REACT_APP_REQUEST_EXISTING_CONSULTANT_SURVEY_ID', REQUEST_EXISTING_CONSULTANT_SURVEY_ID, true],
  ].forEach(([variableName, value, isProdRequiredOnly]) => {
    const shouldLogError = isProdRequiredOnly ? (IS_PRODUCTION && isNil(value)) : isNil(value);
    if (shouldLogError) {
      logger.log(new Error(`Env variable is not defined, ${variableName}`));
    }
  });
}

// Replaces all the matching regx/character by given replacement in actual string
export function replaceAllMatchingCharacters(
  actualString: string, character: string | RegExp, replacement: string,
): string {
  return actualString.replaceAll(character, replacement);
}

// @todo I think we can use formatjs library for formatting
export function formatNumberValue(value: number): number {
  if (!value) return 0;
  return Number.isInteger(value) ? value : +value.toFixed(DECIMAL_POINT);
}

export function convertStringToColor(name: string | undefined, colors: string[]): string {
  const num = !name ? 0 : name.split('').reduce((hash, ch) => hash + ch.charCodeAt(0), 0);
  return colors[num % colors.length];
}

export function calculateColumnSum<TRow>(data: readonly TRow[], columnName: string): number {
  return (data || []).reduce((sum: number, row: TRow) => (
    sum + get(row, columnName, 0)
  ), 0);
}

// converts {number}px => {number}, e.g 24px => 24
export function cssPixelToNumber(px: string): number {
  return +px.replace(/px/, '');
}

export function mailTo(email: string): void {
  window.open(`mailto:${email}`);
}

export function phoneTo(email: string): void {
  window.open(`tel:${email}`);
}

export function ensureValidLink(link: string): string {
  return link.indexOf('http') !== -1 ? link : `//${link}`;
}

export function getLinkUrlName(url = ''): string {
  return first(compact(decodeURIComponent(url).split('/')).pop()?.split('?')) || '';
}

export function faviconSrcFromUrl(url = ''): string {
  return `https://www.google.com/s2/favicons?domain=${new URL(url).hostname}`;
}

/**
 * roundToNearest(5.333333, 0.25) = 5.25
 * roundToNearest(5.8, 0.25) = 5.75
 * roundToNearest(5.92221111, 0.25) = 6
 */
export function roundToNearest(value: number, nearestNumber: number): number {
  const nearest = 1 / nearestNumber;
  return Math.round(value * nearest) / nearest;
}

