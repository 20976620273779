import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import theme from 'Shared/constants/theme';

declare const SpinSizes: ['small', 'default', 'large'];
type SpinSize = typeof SpinSizes[number];

export interface SpinProps {
  className?: string;
  size?: SpinSize;
}

const sizesMap = theme.spin.size;

const getSpinSize = (size?: SpinSize): number => {
  if (!size) return sizesMap.default;
  return sizesMap[size];
};

const Spin: FC<SpinProps> = ({ size, className }) => (
  <LoadingOutlined
    className={className}
    spin
    style={{
      fontSize: getSpinSize(size),
    }}
  />
);

export { Spin };
