import InvalidateCacheLink from './InvalidateCacheLink';
import {
  InvalidateCacheConfig,
  VariablesGetter,
  VariablesGetterReturnType,
  VariablesGetterProps,
} from './InvalidateCacheConfig';

export type {
  InvalidateCacheConfig,
  VariablesGetter,
  VariablesGetterReturnType,
  VariablesGetterProps,
};

export default InvalidateCacheLink;
