import React, { FC, CSSProperties } from 'react';
import { Badge as AntdBadge } from 'antd';
import { BadgeProps as AntdBadgeProps } from 'antd/lib/badge';
import theme from 'Shared/constants/theme';

type BadgeStatus = AntdBadgeProps['status'];
type BadgeType = keyof typeof theme.badge;
type BadgeProps = AntdBadgeProps & {
  type?: BadgeType;
};

const Badge: FC<BadgeProps> = ({
  type,
  ...props
}) => (
  <AntdBadge
    style={{
      ...getColorStyleByType(type),
      ...props.style,
    }}
    {...props}
  />
);

function getColorStyleByType(type?: BadgeType): CSSProperties | undefined {
  if (!type || !theme.badge[type]) return undefined;
  const colorConfig = theme.badge[type];
  return {
    backgroundColor: colorConfig.background,
    color: colorConfig.text,
  };
}

export { Badge };

export type { BadgeProps, BadgeStatus };
