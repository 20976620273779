import { gql } from '@apollo/client';

const WorkspaceContractTemplateFragment = gql`
  fragment WorkspaceContractTemplateData on WorkspaceContractTemplate {
    id
    name
    description
    templateUrl
    dateFormat
    numberFormat
    label
    sortOrder
    contractOwner
	  isProjectRequired
	  isActive
  }
`;

export default WorkspaceContractTemplateFragment;
