import { gql } from '@apollo/client';

const TimesheetDailyRecordFragment = gql`
  fragment TimesheetDailyRecordData on TimesheetDailyRecord {
    date
    plannedUnits
    actualUnits
    isInvoiced
  }
`;

export const TimesheetDailyRecordExtendedFragment = gql`
  fragment TimesheetDailyRecordExtendedData on TimesheetDailyRecordExtended {
    date
    plannedHours
    actualHours
    invoice {
      id
      identifier
    }
    isApproved
    submitedAt
    rejectionReason
  }
`;

export default TimesheetDailyRecordFragment;
