import { Checkbox } from 'antd';
import { CheckboxProps, CheckboxGroupProps as AntdCheckboxGroupProps, CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import theme from 'Shared/constants/theme';
import styled from 'styled-components';

interface CustomProps {
  // renders checkboses either as a list or as a row (vertical = list, horizontal = row)
  direction?: 'vertical' | 'horizontal';
}

type CheckboxesGroupProps = AntdCheckboxGroupProps & CustomProps;

const CheckboxesGroup = styled(Checkbox.Group)(({
  direction = 'horizontal',
}: CustomProps) => ({
  '& .ant-checkbox-wrapper': {
    display: direction === 'horizontal' ? 'inline-flex' : 'flex',
    marginBottom: theme.size.xxs,
  }
}));

export {
  Checkbox,
  CheckboxesGroup,
};

export type { CheckboxProps, CheckboxesGroupProps, CheckboxOptionType, CheckboxValueType };
