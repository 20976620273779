import { gql } from '@apollo/client';
import DocumentTypeFragment from 'WorkspacePortal/api/fragments/Document/DocumentTypeFragment';

const ConsultantDocumentFragment = gql`
  fragment ConsultantDocumentData on ConsultantDocument {
    id
    name
    type {
      ...DocumentTypeData
    }
    status
    startDate
    endDate
    createdDate
    documentFileUrl
    documentDownloadUrl
    s3ResourceKey
  }
  ${DocumentTypeFragment}
`;

export default ConsultantDocumentFragment;
