import { gql } from '@apollo/client';
import ConsultantLevelBaseFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelBaseFragment';

const ConsultantLevelFragment = gql`
  fragment ConsultantLevelData on ConsultantLevel {
    ...ConsultantLevelBase
    currency
    sortOrder
    costRate
    costRateHourly
    clientRate
    clientRateHourly
    description
  }
  ${ConsultantLevelBaseFragment}
`;

export default ConsultantLevelFragment;
