import React, { FC } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'Shared/ui';

const PageLoaderWrap = styled.div({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10%',
});

const PageLoader: FC<unknown> = () => (
  <PageLoaderWrap>
    <Skeleton active />
  </PageLoaderWrap>
);

export default PageLoader;
