/**
 * Returns YYYY-MM-DD string from the given Date
 * @param date Date
 * @returns YYYY-MM-DD
 */
export const serializeToLocalDateString = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

/**
 * Returns YYYY-MM-DD Date from the given Date
 * @param date Date
 * @returns Date from YYYY-MM-DD
 */
export const serializeToLocalDate = (date: Date): Date => {
  return new Date(serializeToLocalDateString(date));
};

/**
 * Removes timezone in the given date
 * @param date iso string
 * @returns Date
 */
export const deserializeToLocalDate = (date: string): Date => {
  return new Date(date.replace(/T.*/gi, ''));
};
