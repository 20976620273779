import React, { FC, ReactElement } from 'react';
import { Row, Col } from 'Shared/ui';

interface Props {
  message: ReactElement | string;
  actions: ReactElement;
}

const NotificationTemplate: FC<Props> = ({ message, actions }) => (
  <Row
    align="middle"
    justify="space-between"
  >
    <Col
      span={20}
      textalign="left"
    >
      {message}
    </Col>
    <Col
      span={4}
      textalign="right"
    >
      {actions}
    </Col>
  </Row>
);

export default NotificationTemplate;
