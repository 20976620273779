import { ApolloClient, gql, QueryOptions } from '@apollo/client';
import { query } from 'Shared/api/helpers';
import { Query, QueryCheckConsultantDuplicatesArgs, QueryIsConsultantEmailAvailableArgs } from '__generated__/graphql/types';
import {
  ConsultantProjectRoleFragment,
  ProjectsOverviewFragment,
  ConsultantDocumentFragment,
  DocumentTypeFragment,
  ConsultantAutocompleteFragment,
  ConsultantContractsTableFragment,
  NoteBaseFragment,
  ConsultantLinkFragment,
} from 'WorkspacePortal/api/fragments';
import ConsultantFullFragment from 'Shared/api/fragments/Consultant/ConsultantFullFragment';
import PageInfoShortFragment from 'Shared/api/fragments/PageInfo/PageInfoShortFragment';
import ConsultantGroupFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupFragment';

export const GET_CONSULTANTS = gql`
  query consultants($filter: ConsultantFilterInput, $sort: ConsultantSortInput, $pagination: PaginationInput) {
    consultants(filter: $filter, sort: $sort, pagination: $pagination) {
      nodes {
        ...ConsultantFullData
      }
      pageInfo {
        ...PageInfoShortData
      }
    }
  }
  ${ConsultantFullFragment}
  ${PageInfoShortFragment}
`;

export const GET_TALENT_MARKETPLACE = gql`
  query talentMarketplace($filter: ConsultantFilterInput, $sort: ConsultantSortInput, $pagination: PaginationInput) {
    talentMarketplace(filter: $filter, sort: $sort, pagination: $pagination) {
      nodes {
        ...ConsultantFullData
      }
      pageInfo {
        ...PageInfoShortData
      }
    }
  }
  ${ConsultantFullFragment}
  ${PageInfoShortFragment}
`;

export const GET_CONSULTANTS_AUTOCOMPLETE = gql`
  query consultantsAutocomplete($search: String!) {
    consultantsAutocomplete(search: $search) {
      ...ConsultantAutocompleteData
    }
  }
  ${ConsultantAutocompleteFragment}
`;

export const IS_CONSULTANT_EMAIL_AVAILABLE = gql`
  query isConsultantEmailAvailable($email: String!) {
    isConsultantEmailAvailable(email: $email)
  }
`;

export const GET_CONSULTANT_CONTRACTS = gql`
  query consultantContracts($consultantId: ID!, $filter: ConsultantContractFilterInput, $sort: ConsultantContractSortInput) {
    consultantContracts(consultantId: $consultantId, filter: $filter, sort: $sort) {
      ...ConsultantContractsTableData
    }
  }
  ${ConsultantContractsTableFragment}
`;

export const GET_CONSULTANT_PROJECT_ROLES = gql`
  query consultantProjectRoles($consultantId: ID!, $filter: ProjectRoleFilterInput, $sort: ProjectRoleSortInput) {
    consultantProjectRoles(consultantId: $consultantId, filter: $filter, sort: $sort) {
      ...ConsultantProjectRoleData
    }
  }
  ${ConsultantProjectRoleFragment}
`;

export const GET_CONSULTANT_PROJECTS_OVERVIEW = gql`
  query consultantProjectsOverview($consultantId: ID) {
    consultantProjectsOverview(consultantId: $consultantId) {
      ...ProjectsOverviewData
    }
  }
  ${ProjectsOverviewFragment}
`;

export const GET_CONSULTANT_DOCUMENTS = gql`
  query consultantDocuments($consultantId: ID, $filter: ConsultantDocumentFilterInput, $sort: ConsultantDocumentSortInput) {
    consultantDocuments(consultantId: $consultantId, filter: $filter, sort: $sort) {
      ...ConsultantDocumentData
    }
  }
  ${ConsultantDocumentFragment}
`;

export const GET_CONSULTANT_DOCUMENT = gql`
  query consultantDocument($documentId: ID!) {
    consultantDocument(documentId: $documentId) {
      ...ConsultantDocumentData
    }
  }
  ${ConsultantDocumentFragment}
`;

export const GET_CONSULTANT_DOCUMENT_TYPES = gql`
  query consultantDocumentTypes {
    consultantDocumentTypes {
      ...DocumentTypeData
    }
  }
  ${DocumentTypeFragment}
`;

export const GET_CONSULTANT_GROUPS = gql`
  query consultantGroups {
    consultantGroups {
      ...ConsultantGroupData
    }
  }
  ${ConsultantGroupFragment}
`;

export const GET_CONSULTANT_NOTES = gql`
  query consultantNotes($consultantId: ID!) {
    consultantNotes(consultantId: $consultantId) {
      ...NoteBaseData
    }
  }
  ${NoteBaseFragment}
`;

export const GET_CONSULTANT_LINKS = gql`
  query consultantLinks($consultantId: ID!) {
    consultantLinks(consultantId: $consultantId) {
      ...ConsultantLinkData
    }
  }
  ${ConsultantLinkFragment}
`;

export const GET_CONSULTANT_LINK = gql`
  query consultantLink($linkId: ID!) {
    consultantLink(linkId: $linkId) {
      ...ConsultantLinkData
    }
  }
  ${ConsultantLinkFragment}
`;

export const CHECK_CONSULTANT_DUPLICATES = gql`
  query checkConsultantDuplicates($emails: [String!]!, $consultantId:ID) {
    checkConsultantDuplicates(emails: $emails, consultantId: $consultantId) 
  }
`;

export const checkConsultantDuplicates = async (
  client: ApolloClient<any>,
  options: Omit<QueryOptions<QueryCheckConsultantDuplicatesArgs>, 'query'>,
): Promise<boolean> => {
  const data = await query<Record<'checkConsultantDuplicates', Query['checkConsultantDuplicates']>, QueryCheckConsultantDuplicatesArgs>(client, {
    query: CHECK_CONSULTANT_DUPLICATES,
    ...options,
  }).catch();
  return !!data;
};

export const isConsultantEmailAvailable = async (
  client: ApolloClient<any>,
  options: Omit<QueryOptions<QueryIsConsultantEmailAvailableArgs>, 'query'>,
): Promise<boolean> => {
  const data = await query<Record<'isConsultantEmailAvailable', Query['isConsultantEmailAvailable']>, QueryIsConsultantEmailAvailableArgs>(client, {
    query: IS_CONSULTANT_EMAIL_AVAILABLE,
    ...options,
  }).catch();
  return !!data?.isConsultantEmailAvailable;
};
