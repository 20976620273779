import { gql } from '@apollo/client';
import IndustryFragment from 'WorkspacePortal/api/fragments/Industry/IndustryFragment';
import UserFragment from 'Shared/api/fragments/User/UserFragment';

const WorkspaceClientsFragment = gql`
  fragment WorkspaceClientsData on WorkspaceClient {
    id
    assignedUser {
      ...UserData
    }
    industry {
      ...IndustryData
    }
    name
    totalProjects
    activeProjects
    grossMarginYtd
    pipelineGrossMarginYtd
    pipelineRevenueYtd
    revenueYtd
  }
  ${UserFragment}
  ${IndustryFragment}
`;

export default WorkspaceClientsFragment;
