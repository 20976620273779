import { Select as AntdSelect } from 'antd';
import {
  SelectProps as AntdSelectProps, OptionProps, LabeledValue, DefaultOptionType, BaseOptionType,
} from 'antd/lib/select';
import styled from 'styled-components';

const {
  OptGroup, Option,
} = AntdSelect;

type CustomProps = {
  textstyling?: boolean;
  block?: boolean;
};

type SelectProps<TValue,
TOption extends DefaultOptionType | BaseOptionType = DefaultOptionType>
= AntdSelectProps<TValue, TOption> & CustomProps;

const Select: typeof AntdSelect = styled(AntdSelect)((props: CustomProps) => ({
  display: props.block ? 'block' : 'inherit',
  '&.ant-select:not(.ant-select-customize-input):not(:hover) .ant-select-selector': {
    borderColor: props.textstyling ? 'transparent' : undefined,
  },
  '.ant-select-arrow': {
    display: props.textstyling ? 'none' : undefined,
  },
}));

export {
  Select,
  OptGroup,
  Option,
};

export type {
  SelectProps,
  OptionProps,
  LabeledValue,
  DefaultOptionType,
};
