import React, { FC } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps } from 'antd/lib/typography/Text';
import styled, { CSSObject } from 'styled-components';
import theme from 'Shared/constants/theme';
import { TextAlign } from './types';

interface AlignProps {
  align?: TextAlign;
}

export type FontSize = keyof typeof theme.fontSize;
type FontProps = {
  fontsize?: FontSize;
  stronger?: boolean;
  extrabold?: boolean;
};

export type TextColor = keyof typeof theme.typography.colors;
type TextColorProps = {
  color?: TextColor;
};
type CustomTitleProps = AlignProps & TextColorProps & FontProps;
type StyledTitleProps = TitleProps & CustomTitleProps;

type CustomParagraphProps = FontProps & AlignProps & TextColorProps;
type StyledParagraphProps = ParagraphProps & CustomParagraphProps;

type CustomTextProps = FontProps & TextColorProps;
type StyledTextProps = TextProps & CustomTextProps;

const {
  Text: AntdText,
  Title: AntdTitle,
  Paragraph: AntdParagraph,
  Link,
} = Typography;

function getLineHeight(fontSize?: FontSize): string | undefined {
  switch (fontSize) {
    case 'm':
      return '24px';
    case 'xl':
      return '32px';
    default:
      // keep default lineHeight
      return undefined;
  }
}

function addAlignProps({ align }: AlignProps): CSSObject {
  return {
    textAlign: align,
  };
}

function addFontProps({ fontsize, stronger, extrabold }: FontProps): CSSObject {
  return {
    fontSize: fontsize && theme.fontSize[fontsize],
    fontWeight: stronger ? theme.typography.text.fontWeight.stronger :
      extrabold ? theme.typography.text.fontWeight.extrabold : undefined,
    lineHeight: getLineHeight(fontsize),
  };
}

function addColorProps({ color }: TextColorProps): CSSObject {
  return {
    color: color ? theme.typography.colors[color] : undefined,
  };
}

function addCodeStyles(): CSSObject {
  return {
    color: 'inherit',
    padding: '2px 8px',
    border: `1px solid ${theme.colors.neutral[500]}`,
    borderRadius: '2px',
    margin: 0,
    fontSize: 'inherit',
    fontFamily: 'inherit',
    display: 'inline-block',
    background: theme.colors.neutral[200]
  };
}

const Title = styled<FC<StyledTitleProps>>(AntdTitle)<CustomTitleProps>((props) => ({
  '&&&': {
    ...addFontProps(props),
    ...addAlignProps(props),
    ...addColorProps(props),
    'code': addCodeStyles(),
  },
}));

const Paragraph = styled<FC<StyledParagraphProps>>(AntdParagraph)<CustomParagraphProps>(
  (props) => ({
    '&&&': {
      ...addFontProps(props),
      ...addAlignProps(props),
      ...addColorProps(props),
      'code': addCodeStyles(),
    }
  }),
);
const Text = styled<FC<StyledTextProps>>(AntdText)<CustomTextProps>((props) => ({
  '&&&': {
    ...addFontProps(props),
    ...addColorProps(props),
    'code': addCodeStyles(),
  },
}));

const Email: FC<{ value: string }> = ({ value }) => (
  <Link href={`mailto:${value}`}>{value}</Link>
);

export {
  Text, Paragraph, Link, Title, Email,
};
