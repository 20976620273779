import React, { ReactElement } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';

import 'App.less';
import PublicPagesRouter from 'Shared/routers/PublicPagesRouter';
import AppIntlProvider from 'Shared/components/Common/Intl/AppIntlProvider';
import { apiClient, history } from 'globalModules';

function App(): ReactElement {
  return (
    <Router history={history}>
      <ApolloProvider client={apiClient}>
        <AppIntlProvider>
          <div className="App">
            <PublicPagesRouter />
          </div>
        </AppIntlProvider>
      </ApolloProvider>
    </Router>
  );
}

export default App;
