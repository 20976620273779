import { Modal } from 'antd';
import { ModalProps, ModalFuncProps } from 'antd/lib/modal';
import { ModalFunc } from 'antd/lib/modal/confirm';
import theme from 'Shared/constants/theme';
import styled from 'styled-components';

const FullScreenModal = styled(Modal)({
  '& .ant-modal-content': {
    height: '100vh',
    width: '100%',
    background: 'transparent',
    overflow: 'hidden',
    padding: theme.size.xxxl,
  },
  '&.ant-modal': {
    paddingBottom: 0,
    top: 0,
    width: '100% !important', // rewrite inline style
    maxWidth: '100%',
  },
  '& .ant-modal-body': {
    height: '100%',
    padding: 0,
    background: 'white',
  },
  '& .ant-modal-close-x': {
    width: theme.size.xxxl,
    height: theme.size.xxxl,
    lineHeight: theme.size.xxxl,
    color: theme.colors.white.base,
    fontSize: theme.fontSize.l,
  },
});

export { Modal, FullScreenModal };

export type { ModalProps, ModalFuncProps, ModalFunc };
