import { gql } from '@apollo/client';

const ConsultantGroupBaseFragment = gql`
  fragment ConsultantGroupBaseData on ConsultantGroup {
    id
    name
  }
`;

export default ConsultantGroupBaseFragment;
