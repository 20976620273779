import { gql } from '@apollo/client';
import UserFragment from 'Shared/api/fragments/User/UserFragment';

const JobsFragment = gql`
  fragment JobOpportunityData on JobOpportunity {
    id
    status
    updatedAt
    title
    description
    owner {
      ...UserData
    }
    body
    duration
    daysPerWeek
    location
    isRemote
    rate
    currency
    unitType
    startDate
    isASAP
    type
    isPublic
    isApplied
  }
  ${UserFragment}
`;

export default JobsFragment;

