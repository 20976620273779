/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */

export const DEFAULT_LOCALE = 'en';
export const RIVERFLEX_WEBSITE = 'www.riverflex.com';
export const SPLASH_TERMS_PAGE_LINK = 'https://getsplash.eu/terms-and-conditions';
export const PAGINATION_PAGE_SIZE = 12;
export const DRAWER_ANIMATION_TIME = 300;
export const DAYS_INPUT_STEP = 0.5;
export const DURATION_INPUT_STEP = 0.25;
export const COST_INPUT_STEP = 0.25;
export const DECIMAL_POINT = 2;
export const EUROPE_TIMEZONE = 'Europe/London';

export const ACTIVE_WORKSPACE_ID_STORAGE_NAME = 'activeWorkspaceId';
export const SHOW_CP_WELCOME_STORAGE_NAME = 'showCPWelcome';
export const AFTER_LOGIN_REDIRECT = 'afterLoginRedirect';

// env variables
export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
export const IS_STAGING = process.env.REACT_APP_ENVIRONMENT === 'staging';
export const IS_LOCALHOST = process.env.REACT_APP_ENVIRONMENT === 'localhost';
export const IS_UAT = process.env.REACT_APP_ENVIRONMENT === 'uat';
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const API_URL = process.env.REACT_APP_API_URL;
export const IS_CONNECTED_TO_APOLLO_DEV_TOOLS = process.env.REACT_APP_CONNECT_TO_DEV_TOOLS === 'true';
export const AUTH_TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_NAME;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const BACKEND_LINKEDIN_URL =  `${API_URL}/linkedin/login/`;
export const CV_SEARCH_INPUT_PLACEHOLDER =  '"Project Manager" London';
export const CV_SEARCH_INPUT_TOOLTIP_MESSAGE =  'To improve search results use quotation marks to indicate that words belong together.';
export const CV_SEARCH_INPUT_TOOLTIP_EXAMPLE =  'Example: "Project Manager" London, instead of Project Manager London';
export const WORKSPACE_PUBLIC_PATH = ':workspaceIdentifier/public';
export const PUBLIC_JOB_PAGE_ROUTE = `/${WORKSPACE_PUBLIC_PATH}/jobs/:jobId`;
export const PUBLIC_JOBS_PAGE_ROUTE = `/${WORKSPACE_PUBLIC_PATH}/jobs`;
