import { ApolloClient, DocumentNode, MutationOptions, QueryOptions, Operation } from '@apollo/client';
import { FieldNode, getOperationAST, FragmentDefinitionNode } from 'graphql';
import { getMainDefinition } from '@apollo/client/utilities';

export async function query<TReturnType, TVariables>(
  client: ApolloClient<any>, options: QueryOptions<TVariables, TReturnType>,
): Promise<TReturnType> {
  const queryResponse = await client.query<TReturnType, TVariables>(options);
  return queryResponse.data;
}

export async function mutate<TReturnType, TVariables>(
  client: ApolloClient<any>, options: MutationOptions<TReturnType, TVariables>,
): Promise<TReturnType | void | null> {
  const mutation = await client.mutate<TReturnType, TVariables>(options);
  return mutation.data;
}

export function getQueryNameFromGQLDoc(gqlDoc: DocumentNode): string {
  const operation = getOperationAST(gqlDoc);
  const fieldNode = operation?.selectionSet?.selections?.[0] as FieldNode;
  if (!fieldNode.name.value) {
    throw new Error('operation name is not found!');
  }
  return fieldNode.name.value;
}

// @todo find in the graphqljs docs if there is an utility funciton for this
// https://graphql.org/graphql-js/utilities/
export function getFragmentTypeName(fragmentDoc: DocumentNode): string | undefined {
  const fragment = fragmentDoc.definitions.find((node) => node.kind === 'FragmentDefinition') as FragmentDefinitionNode;
  return fragment?.typeCondition?.name.value;
}

export function isOperationMutation(operation: Operation): boolean {
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'mutation';
}
