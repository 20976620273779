import { gql } from '@apollo/client';
import UserFragment from 'Shared/api/fragments/User/UserFragment';
import WorkspaceFragment from 'Shared/api/fragments/Workspace/WorkspaceFragment';

const MeFragment = gql`
  fragment MeData on Me {
    user {
      ...UserData
    }
    workspaces {
      ...WorkspaceData
    }
    workspaceUserId
  }
  ${UserFragment}
  ${WorkspaceFragment}
`;

export default MeFragment;
