import { gql } from '@apollo/client';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';
import UserFragment from 'Shared/api/fragments/User/UserFragment';
import CommonProjectFieldsFragment from './CommonProjectFieldsFragment';

const ClientProjectsScenarioFragment = gql`
  fragment ClientProjectsScenarioData on ProjectScenario {
    ...ProjectScenarioRequiredData
    startDate
    endDate
    revenue
    grossMargin
    grossMarginPct
  }
  ${ProjectScenarioRequiredDataFragment}
`;

const ClientProjectsFragment = gql`
  fragment ClientProjectsData on Project {
    client {
      id
    }
    scenario {
      ...ClientProjectsScenarioData
    }
    ...CommonProjectFields
  }
  ${UserFragment}
  ${CommonProjectFieldsFragment}
  ${ClientProjectsScenarioFragment}
`;

export default ClientProjectsFragment;
