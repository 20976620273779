import { Menu } from 'antd';
import { MenuProps, MenuItemGroupProps } from 'antd/lib/menu';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';

const {
  Item,
  SubMenu,
  ItemGroup,
  Divider,
} = Menu;

export {
  Menu,
  Item as MenuItem,
  SubMenu,
  ItemGroup as MenuItemGroup,
  Divider as MenuItemDivider,
};

export type { MenuProps, MenuItemGroupProps, MenuItemProps, ItemType };
