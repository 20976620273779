import {
  ApolloLink, Operation, NextLink, Observable, FetchResult, InMemoryCache,
} from '@apollo/client';
import omitBy from 'lodash/omitBy';

import {
  InvalidateCacheConfig,
  ExtendedCacheConfig,
} from './InvalidateCacheConfig';
import { isOperationMutation } from 'Shared/api/helpers';

class InvalidateCacheLink<MutationName extends string, QueryName extends string>
  extends ApolloLink {
  constructor(
    readonly cache: InMemoryCache,
    readonly invalidateCacheConfig: InvalidateCacheConfig<MutationName, QueryName>,
  ) {
    super();
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    // console.log(`starting request for ${operation.operationName}`);
    return forward?.(operation).map((data) => {
      // console.log(`ending request for ${operation.operationName}`);
      if (data.errors || !data.data) return data;

      if (!isOperationMutation(operation)) return data;

      const operationConfig = (
        this.invalidateCacheConfig[operation.operationName as MutationName]
      );

      setTimeout(() => this.invalidateCacheAfterOperation(operation), operationConfig?.delay);
      return data;
    });
  }

  private invalidateCacheAfterOperation(operation: Operation): void {
    const cachedQueries = this.cache.extract().ROOT_QUERY;
    if (!cachedQueries) return;

    Object.keys(cachedQueries).forEach((key) => {
      if (!this.shouldInvalidateCacheItem(key, operation)) return;
      // console.log(`DELETING ${key}`);
      this.cache.evict({ id: 'ROOT_QUERY', fieldName: key, broadcast: true });
      // (this.cache as any).data.delete(key);
      // delete cacheData[key];
    });
    this.cache.gc();
  }

  private shouldInvalidateCacheItem(cacheKey: string, operation: Operation): boolean {
    const { operationName, variables, getContext } = operation;
    const context = getContext();
    const operationConfig = this.invalidateCacheConfig[operationName as MutationName];
    if (!operationConfig) return false;

    // eslint-disable-next-line prefer-destructuring
    let invalidateOperations = operationConfig.invalidateOperations || [];

    if ((operationConfig as ExtendedCacheConfig<MutationName>).extend) {
      // @todo add multiple or chainable extend
      const extendingConfigName = (operationConfig as ExtendedCacheConfig<MutationName>).extend;
      const extendingConfig = this.invalidateCacheConfig[extendingConfigName];
      const extendingConfigOperations = extendingConfig?.invalidateOperations || [];
      invalidateOperations = [...extendingConfigOperations, ...invalidateOperations];
    }

    if (!invalidateOperations) return false;
    const regExps = invalidateOperations.reduce((acc: RegExp[], item) => {
      if (!item.variables) {
        return [...acc, new RegExp(`${item.operationName}\\(\\{\\}\\)`), new RegExp(`^${item.operationName}$`)];
      }

      const invalidQueryVariables = item.variables({ variables, context, cache: this.cache });
      if (!invalidQueryVariables) return acc;
      const invalidOperationVariables = omitBy(
        invalidQueryVariables,
        (val) => !val,
      );

      const regexpStr = Object.entries(invalidOperationVariables)
        .reduce((regex: string, [variableName, variableValue]) => {
          const exp = typeof variableValue === 'object'
            ? JSON.stringify(variableValue).replace(/\[/g, '\\[').replace(/\]/g, '\\]')
            : `"${variableValue}"`;
          return `${regex}(?=.*"${variableName}":${exp})`;
        }, `(?=.*${item.operationName})`);

      return [...acc, new RegExp(regexpStr)];
    }, []);

    return regExps.some((regexp) => cacheKey.match(regexp));
  }
}

export default InvalidateCacheLink;
