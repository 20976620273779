import { gql } from '@apollo/client';

const EntityPreviewFragment = gql`
  fragment EntityPreviewData on EntityPreview {
    id
    name
    pictureUrl
    description
  }
`;

export default EntityPreviewFragment;
