import { gql } from '@apollo/client';
import ConsultantGroupBaseFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupBaseFragment';


const ConsultantAutocompleteFragment = gql`
  fragment ConsultantAutocompleteData on WorkspaceConsultant {
    id
    name
    avatarUrl
    group {
      ...ConsultantGroupBaseData
    }
    level {
      id
      name
      costRate
      clientRate
      currency
    }
    type
    costRate
    currency
  }
  ${ConsultantGroupBaseFragment}
`;

export default ConsultantAutocompleteFragment;
