import { gql } from '@apollo/client';

const SubscriptionPackageFragment = gql`
  fragment SubscriptionPackageData on SubscriptionPackage {
    id
    name
    pricePerActiveConsultant
    #description
    #activeConsultantsLimit
    #activeCollectivesLimit
    #features
  }
`;

export default SubscriptionPackageFragment;
