import { useApolloClient } from '@apollo/client';

import { AvailablePortal, WorkspaceDataFragment } from '__generated__/graphql/types';
import useGetFromCache from 'Shared/hooks/useGetFromCache';
import { activeWorkspaceStorage, passwordAuthService } from 'globalModules';
import { getAppRootPath } from 'Shared/hooks/useIdentifyAppPortal/useIdentifyAppPortal';

interface Options {
  silent?: boolean; // doesnt clear store, just changes ws id in localStorate
  redirectPath?: string; // location path is added to default appRootPath, ie. appRootPath/redirectPath, if not provided then redirect on appRootPath
  portal: AvailablePortal;
}

interface ReturnValue {
  changeActiveWorkspace: (
    workspace: Pick<WorkspaceDataFragment, 'id' | 'identifier' | 'portals'>,
    options: Options,
  ) => Promise<void>;
  activeWorkspace: WorkspaceDataFragment | undefined;
}

function useActiveWorkspace(): ReturnValue {
  const { getActiveWorkspace } = useGetFromCache();
  const activeWorkspace = getActiveWorkspace();
  const client = useApolloClient();

  const changeActiveWorkspace: ReturnValue['changeActiveWorkspace'] = async (workspace, options) => {
    activeWorkspaceStorage.storeId(workspace.id);
    if (options.silent) return;

    await client.clearStore();

    if (!workspace.portals.includes(options.portal)) {
      throw new Error(`${workspace.identifier} doesn't contain portal "${options.portal}"`);
    }

    const appRootPath = getAppRootPath(workspace.identifier, options.portal);
    passwordAuthService.hardRefresh(`/${appRootPath}/${options?.redirectPath || ''}`);
  };

  return {
    changeActiveWorkspace,
    activeWorkspace,
  };
}

export default useActiveWorkspace;
