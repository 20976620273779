import {
  InvalidateCacheConfig,
  VariablesGetterProps,
} from 'Shared/api/links/InvalidateCacheLink';
import { QueryName, MutationName } from 'Shared/types/api';
import { Scalars, WorkspaceProjectQueryVariables } from '__generated__/graphql/types';
import { ApiNotificationContext } from '../../links/ApiNotificationLink';

type VariablesProps<TMutationVariables, TContext = any,
  > = VariablesGetterProps<TMutationVariables, TContext>;

export interface UpdateProjectInvoiceContext extends ApiNotificationContext {
  meta: {
    invoiceId: Scalars['ID']
  }
}

const invoiceInvalidationConfig: InvalidateCacheConfig<MutationName, QueryName> = {
  updateProjectInvoice: {
    invalidateOperations: [
      { operationName: 'projectInvoices' },
      {
        operationName: 'workspaceProject',
        variables: ({ variables }: VariablesProps<WorkspaceProjectQueryVariables>) => ({
          projectId: variables.projectId,
        })
      },
    ]
  },
  updateWorkspaceSettings: {
    invalidateOperations: [
      { operationName: 'workspaceSettings', variables: () => ({ }) }
    ]
  }
};

export default invoiceInvalidationConfig;
