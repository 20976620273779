import React, { FC, CSSProperties } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { WithClassName } from 'Shared/types/helpers';

interface CenterProps {
  direction?: 'vertical' | 'horizontal';
}

const Center = styled.div(({ direction = 'vertical' }: CenterProps) => ({
  display: 'flex',
  'flex-direction': direction === 'vertical' ? 'column' : 'row',
  alignItems: 'center',
  justifyContent: 'center',
}));

const VerticalCenter = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});

type CenterBoxProps = WithClassName & {
  direction: 'vertical' | 'horizontal',
  style?: CSSProperties;
};

const CenterBox: FC<CenterBoxProps> = ({
  direction,
  children,
  ...rest
}) => {
  switch (direction) {
    case 'vertical':
      return <VerticalCenter {...rest}>{children}</VerticalCenter>;
    case 'horizontal':
      return <Center {...rest}>{children}</Center>;

    default:
      return <></>;
  }
};

const SpaceBetween = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export { Layout, Center, VerticalCenter, CenterBox, SpaceBetween };
