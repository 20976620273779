import { gql } from '@apollo/client';
import ConsultantBaseFragment from 'Shared/api/fragments/Consultant/ConsultantBaseFragment';
import ConsultantGroupBaseFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupBaseFragment';
import ConsultantLevelFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelFragment';
import ProjectRoleOnboardingStateFragment from 'WorkspacePortal/api/fragments/ProjectRole/ProjectRoleOnboardingStateFragment';
import ProjectScenarioRequiredDataFragment from 'WorkspacePortal/api/fragments/ProjectScenario/ProjectScenarioRequiredDataFragment';

const ProjectRoleLoadedLevelFragment = gql`
  fragment ProjectRoleLoadedLevelData on ProjectRoleLoadedLevel {
    id
    currency
    name
    costRate
    clientRate
  }
`;

const ProjectRoleFragment = gql`
  fragment ProjectRoleData on ProjectRole {
    id
    consultant {
      ...ConsultantBaseData
      level {
        ...ConsultantLevelData
      }
      group {
        ...ConsultantGroupBaseData
      }
      isDisabled
      type
    }
    project {
      id
      locked
      currency
      stage
      scenario {
        ...ProjectScenarioRequiredData
      }
    }
    role
    startDate
    endDate
    units
    unitsPerWeek
    unitType
    costRate
    currency
    clientRate
    grossMarginPct
    grossMargin
    revenue
    budgetedRevenue
    budgetedCost
    budgetedGrossMargin
    budgetedGrossMarginPct
    onboardingState {
      ...ProjectRoleOnboardingStateData
    }
    loadedLevel {
      ...ProjectRoleLoadedLevelData
    }
    isManualCollected
    level {
      ...ConsultantLevelData
    }
    actualUnits
    plannedUnits
  }
  ${ConsultantBaseFragment}
  ${ConsultantLevelFragment}
  ${ConsultantGroupBaseFragment}
  ${ProjectRoleOnboardingStateFragment}
  ${ProjectScenarioRequiredDataFragment}
  ${ProjectRoleLoadedLevelFragment}
`;

export default ProjectRoleFragment;
