import { gql } from '@apollo/client';
import ConsultantAvailabilityFragment from 'Shared/api/fragments/ConsultantAvailability/ConsultantAvailabilityFragment';
import ConsultantGroupFragment from 'Shared/api/fragments/ConsultantGroup/ConsultantGroupFragment';
import ConsultantLevelFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelFragment';
import UserFragment from 'Shared/api/fragments/User/UserFragment';
//import ConsultantHighlightFragment from '../Highlight/ConsultantHighlightFragment';

const ConsultantFullFragment = gql`
  fragment ConsultantFullData on WorkspaceConsultant {
    id
    name
    email
    emails
    about
    avatarUrl
    linkedin
    location
    timezone
    phone
    preferredIndustries
    preferredRoles
    website
    role
    cvUrl
    revenue
    grossMargin
    grossMarginPct
    pipelineGrossMargin
    pipelineRevenue
    nps
    isShared
    isFavorite
    isRequested
    group {
      ...ConsultantGroupData
    }
    level {
      ...ConsultantLevelData
    }
    type
    costRate
    isDisabled
    disabledAt
    disabledByUser {
      ...UserData
    }
    availability {
      ...ConsultantAvailabilityData
    }
    currency
    textMatches
  }
  ${ConsultantLevelFragment}
  ${ConsultantGroupFragment}
  ${UserFragment}
  ${ConsultantAvailabilityFragment}
`;

export default ConsultantFullFragment;
