import { GraphQLError } from 'graphql';
import { ServerError, ServerParseError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import pick from 'lodash/pick';
import { History } from 'history';

import { MeQuery } from '__generated__/graphql/types';
import { IS_PRODUCTION, SENTRY_DSN, APP_ENVIRONMENT } from 'Shared/constants/index';
import { IActiveWorkspaceStorage } from 'Shared/services/activeWorkspace/ActiveWorkspaceStorage';

type AnyError = GraphQLError | Error | ServerError | ServerParseError;
interface ExtraData {
  [key: string]: any;
}

export interface ILogger {
  setupScope(me: NonNullable<MeQuery['me']>): void;
  clearScope(): void;
  log(messageOrError: AnyError | string, extra?: ExtraData): void;
}

class SentryLogger implements ILogger {
  constructor(
    private readonly sentry: typeof Sentry,
    private readonly activeWorkspaceStorage: IActiveWorkspaceStorage,
    private readonly history: History,
  ) {
    if (!SENTRY_DSN) return;
    this.sentry.init({
      dsn: SENTRY_DSN,
      environment: APP_ENVIRONMENT,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: this.sentry.reactRouterV5Instrumentation(history),
        }),
      ],
    });
    if (APP_ENVIRONMENT) {
      this.sentry.setTag('environment', APP_ENVIRONMENT);
    }
  }

  setupScope(me: NonNullable<MeQuery['me']>): void {
    const activeWorkspace = this.activeWorkspaceStorage.find(me.workspaces);
    const userData = pick(me.user, ['id', 'firstName', 'lastName']);
    this.sentry.setUser(userData);

    if (activeWorkspace?.identifier) {
      this.sentry.setTag('workspace.identifier', activeWorkspace.identifier);
      this.sentry.setContext('workspace', {
        ...pick(activeWorkspace, ['id', 'accessLevel', 'identifier']),
      });
    }
  }

  clearScope(): void {
    this.sentry.configureScope((scope) => {
      scope.setUser(null);
      scope.clear();
    });
  }

  log(error: AnyError | string, extra?: ExtraData): void {
    if (typeof error === 'string') {
      this.sentry.captureMessage(error, { extra });
      return;
    }
    this.sentry.captureException(error, { extra });
    if (!IS_PRODUCTION) {
      // eslint-disable-next-line no-console
      console.log(error, extra);
    }
  }
}

let sentryLogger: ILogger;

type Props = {
  activeWorkspaceStorage: IActiveWorkspaceStorage;
  history: History;
};

export function initLogger({
  activeWorkspaceStorage,
  history,
}: Props): ILogger {
  if (sentryLogger) return sentryLogger;
  sentryLogger = new SentryLogger(Sentry, activeWorkspaceStorage, history);
  return sentryLogger;
}
