import { gql } from '@apollo/client';
import JobApplicationFragment from 'Shared/api/fragments/JobApplication/JobApplicationFragment';
import JobsFragment from 'Shared/api/fragments/Jobs/JobsFragment';
import PageInfoShortFragment from 'Shared/api/fragments/PageInfo/PageInfoShortFragment';
import { JobApplicationStatusFragment } from 'WorkspacePortal/api/fragments';

export const GET_JOB_OPPORTUNITIES = gql`
  query jobOpportunities($filter: JobOpportunityFilter) {
    jobOpportunities(filter: $filter) {
     ...JobOpportunityData
    }
  }
  ${JobsFragment}
`;

export const GET_JOB_OPPORTUNITY = gql`
  query jobOpportunity($id: ID!) {
    jobOpportunity(id: $id) {
     ...JobOpportunityData
    }
  }
  ${JobsFragment}
`;

export const GET_PUBLIC_JOB_LOCATIONS = gql`
  query publicJobOpportunitiesLocations($workspaceIdentifier: String!) {
    publicJobOpportunitiesLocations(workspaceIdentifier: $workspaceIdentifier)
  }
`;

export const GET_PUBLIC_JOB_OPPORTUNITIES = gql`
  query publicJobOpportunities($workspaceIdentifier: String!, $filter: JobOpportunityFilter) {
    publicJobOpportunities(workspaceIdentifier: $workspaceIdentifier, filter: $filter) {
     ...JobOpportunityData
    }
  }
  ${JobsFragment}
`;

export const GET_PUBLIC_JOB_OPPORTUNITY = gql`
  query publicJobOpportunity($id: ID!) {
    publicJobOpportunity(id: $id) {
     ...JobOpportunityData
    }
  }
  ${JobsFragment}
`;

export const GET_JOB_APPLICATION_STATUSES = gql`
  query jobApplicationStatuses {
    jobApplicationStatuses {
     ...JobApplicationStatusData
    }
  }
  ${JobApplicationStatusFragment}
`;

export const GET_COUNT_WORKSPACE_CONSULTANTS = gql`
  query countWorkspaceConsultants {
    countWorkspaceConsultants
  }
`;

export const GET_JOB_APPLICATIONS = gql`
  query jobApplications($jobOpportunityId: ID!, $filter: JobApplicationFilterInput, $sort: JobApplicationSortInput, $pagination: PaginationInput) {
    jobApplications(jobOpportunityId: $jobOpportunityId, filter: $filter, sort: $sort, pagination: $pagination) {
      nodes {
        ...JobApplicationData
      }
      pageInfo {
        ...PageInfoShortData
      }
    }
  }
  ${JobApplicationFragment}
  ${PageInfoShortFragment}
`;

export const GET_JOB_APPLICATION = gql`
  query jobApplication($id: ID!) {
    jobApplication(id: $id) {
     ...JobApplicationData
    }
  }
  ${JobApplicationFragment}
`;