import { Upload } from 'antd';
import {
  UploadProps, UploadListProps, UploadChangeParam, RcFile, DraggerProps,
} from 'antd/lib/upload';
import { UploadFile, InternalUploadFile } from 'antd/lib/upload/interface';

const {
  Dragger,
} = Upload;

export type {
  UploadProps,
  UploadListProps,
  UploadChangeParam,
  RcFile,
  DraggerProps,
  UploadFile,
  InternalUploadFile,
};

export { Upload, Dragger };
