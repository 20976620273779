import { Tree } from 'antd';
import { DataNode, TreeProps } from 'antd/lib/tree';
import styled from 'styled-components';

export type { DataNode as TreeDataNode, TreeProps };

const StyledTree = styled(Tree)({
  background: 'transparent',
}) as typeof Tree;

export { StyledTree as Tree };
