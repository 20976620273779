import { gql } from '@apollo/client';
import { ConsultantContractFragment, InitialDAContractDataFragment, WorkspaceContractTemplateFragment } from 'WorkspacePortal/api/fragments';

export const GET_CONSULTANT_CONTRACT = gql`
  query consultantContract($contractId: ID!) {
    consultantContract(contractId: $contractId) {
      ...ConsultantContractData
    }
  }
  ${ConsultantContractFragment}
`;

export const GET_CONTRACT_TYPES = gql`
  query contractTypes {
    contractTypes
  }
`;

export const GET_CONSULTANT_CONTRACT_TYPES = gql`
  query consultantContractTypes {
    consultantContractTypes
  }
`;

export const GET_INITIAL_DA_CONTRACT_INFO = gql`
  query initialConsultantDAContractInfo($projectId: ID!, $consultantId: ID!) {
    initialConsultantDAContractInfo(projectId: $projectId, consultantId: $consultantId) {
      ...InitialDAContractDataFields
    }
  }
  ${InitialDAContractDataFragment}
`;

export const GET_SERVICE_AGREEMENT_REF = gql`
  query serviceAgreementRef($projectId: ID!, $filter: ConsultantContractFilterInput, $sort: ConsultantContractSortInput) {
    serviceAgreementRef: projectConsultantContracts(projectId: $projectId, filter: $filter, sort: $sort) {
      id
      name
      status
    }
  }
`;

export const GET_DOCUSIGN_CONNECTION_STATE = gql`
  query docusignConnectionState {
    docusignConnectionState {
      isConnected
      codegrantUrl
    }
  }
`;

export const GET_ADDABLE_CONSULTANT_CONTRACT_TYPES = gql`
  query addableConsultantContractTypes($consultantId: ID!) {
    addableConsultantContractTypes(consultantId: $consultantId)
  }
`;

export const GET_ADDABLE_PROJECT_CONTRACT_TYPES = gql`
  query addableProjectContractTypes($consultantId: ID!, $projectId: ID) {
    addableProjectContractTypes(consultantId: $consultantId, projectId: $projectId)
  }
`;

export const GET_CLIENT_CONTRACT_SENDER_VIEW_URL = gql`
  query clientContractSenderViewUrl($contractId: ID!) {
    contractSenderViewUrl: clientContractSenderViewUrl(contractId: $contractId)
  }
`;

export const GET_CONSULTANT_CONTRACT_SENDER_VIEW_URL = gql`
  query consultantContractSenderViewUrl($contractId: ID!) {
    contractSenderViewUrl: consultantContractSenderViewUrl(contractId: $contractId)
  }
`;

export const GET_WORKSPACE_CONTRACT_TEMPLATES = gql`
  query workspaceContractTemplates($filter: WorkspaceContractTemplateFilterInput) {
    workspaceContractTemplates(filter: $filter) {
      ...WorkspaceContractTemplateData
    }
  }
  ${WorkspaceContractTemplateFragment}
`;

export const GET_WORKSPACE_CONTRACT_TEMPLATE = gql`
  query workspaceContractTemplate($templateId: ID!) {
    workspaceContractTemplate(templateId: $templateId) {
      ...WorkspaceContractTemplateData
    }
  }
  ${WorkspaceContractTemplateFragment}
`;
