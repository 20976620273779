import { Steps } from 'antd';
import { StepsProps, StepProps } from 'antd/lib/steps';

const {
  Step,
} = Steps;

export type { StepsProps, StepProps };

export { Steps, Step };
