import { CLIENT_PORTAL_ROOT_PATH } from 'ClientPortal/constants';
import { LOGIN_PAGE_PATH } from 'WorkspacePortal/constants/index';
import { CONSULTANT_PORTAL_ROOT_PATH } from 'ConsultantPortal/constants';
import { ADMIN_PORTAL_ROOT_PATH } from 'AdminPortal/constants';
import { AvailablePortal } from '__generated__/graphql/types';

export const CONSULTANT_LOGIN_PAGE_REACT_ROUTE = `/${CONSULTANT_PORTAL_ROOT_PATH}/${LOGIN_PAGE_PATH}/:workspaceIdentifier`;
export const CLIENT_LOGIN_PAGE_REACT_ROUTE = `/${getClientPortalLoginPagePath()}`;
export const ADMIN_LOGIN_PAGE_REACT_ROUTE = `/${getAdminPortalLoginPagePath()}`;
export const WORKSPACE_LOGIN_PAGE_REACT_ROUTE = `/${getWorkspacePortalLoginPagePath()}`;

export function getWorkspacePortalLoginPagePath(): string {
  return LOGIN_PAGE_PATH;
}

export function getConsultantPortalLoginPagePath(workspaceIdentifier: string): string {
  return `${CONSULTANT_PORTAL_ROOT_PATH}/${LOGIN_PAGE_PATH}/${workspaceIdentifier}`;
}

export function getClientPortalLoginPagePath(): string {
  return `${CLIENT_PORTAL_ROOT_PATH}/${LOGIN_PAGE_PATH}`;
}

export function getAdminPortalLoginPagePath(): string {
  return `${ADMIN_PORTAL_ROOT_PATH}/${LOGIN_PAGE_PATH}`;
}

export function getLoginPagePath(portal: AvailablePortal, workspace: string): string {
  switch (portal) {
    case AvailablePortal.Workspace:
      return getWorkspacePortalLoginPagePath();
    case AvailablePortal.Consultant:
      return getConsultantPortalLoginPagePath(workspace);
    case AvailablePortal.Client:
      return getClientPortalLoginPagePath();
    default:
      throw new Error(`Login path for ${portal} is not defined!`);
  }
}
