import { gql } from '@apollo/client';
import ConsultantLevelFragment from 'Shared/api/fragments/ConsultantLevel/ConsultantLevelFragment';
import CurrencyRateFragment from 'Shared/api/fragments/Currency/CurrencyRateFragment';
import {
  WorkspaceInvoiceHistoricalRecordFragment,
  WorkspaceSettingsFragment,
  WorkspaceLocationFragment,
  ExpenseCategoryFragment,
  WorkspaceUserFragment,
  UserProfileFragment,
  WorkspacePublicHolidayFragment,
} from 'WorkspacePortal/api/fragments';

export const GET_WORKSPACE_SETTINGS = gql`
  query workspaceSettings {
    workspaceSettings {
      ...WorkspaceSettingsData
    }
  }
  ${WorkspaceSettingsFragment}
`;

export const GET_WORKSPACE_LOCATIONS = gql`
  query workspaceLocations {
    workspaceLocations {
      ...WorkspaceLocationData
    }
  }
  ${WorkspaceLocationFragment}
`;

export const GET_CONSULTANT_LEVELS = gql`
  query consultantLevels {
    consultantLevels {
      ...ConsultantLevelData
    }
  }
  ${ConsultantLevelFragment}
`;

export const GET_EXPENSE_CATEGORIES = gql`
  query expenseCategories {
    expenseCategories {
      ...ExpenseCategoryData
    }
  }
  ${ExpenseCategoryFragment}
`;

export const COUNT_EXPENSE_CATEGORY_ENTRIES = gql`
  query countExpenseCaterogyEntries($categoryId: ID!) {
    countExpenseCaterogyEntries(categoryId: $categoryId)
  }
`;

export const GET_WORKSPACE_USERS = gql`
  query workspaceMemberUsersAndInvites($filter: WorkspaceUsersAndInvitesFilterInput, $sort: WorkspaceUsersAndInvitesSortInput) {
    workspaceMemberUsersAndInvites(filter: $filter, sort: $sort) {
      ...WorkspaceUserData
    }
  }
  ${WorkspaceUserFragment}
`;

export const GET_USER_PROFILE = gql`
  query userProfile {
    userProfile {
      ...UserProfileData
    }
  }
  ${UserProfileFragment}
`;

export const GET_BILLING_HISTORY = gql`
  query workspaceInvoiceHistoricalRecords {
    workspaceInvoiceHistoricalRecords {
      ...WorkspaceInvoiceHistoricalRecordData
    }
  }
  ${WorkspaceInvoiceHistoricalRecordFragment}
`;

export const GET_WORKSPACE_CURRENCY_RATES = gql`
  query workspaceCurrencyRates {
    workspaceCurrencyRates {
      ...CurrencyRateData
    }
  }
  ${CurrencyRateFragment}
`;

export const GET_WORKSPACE_PUBLIC_HOLIDAYS = gql`
  query workspacePublicHolidays($year: Int) {
    workspacePublicHolidays(year: $year) {
      ...WorkspacePublicHolidayData
    }
  }
  ${WorkspacePublicHolidayFragment}
`;
